<div *ngIf="!usesExportPlugin">
  <mat-toolbar>
    <mat-toolbar-row class="toolbar-row">
      <!--   LEFT SIDE   -->
      <div class="left-side">
        <ng-container
          *ngIf="
            this.authService.isInAiLicenceGroup ||
            this.authService.isInPorterLicenceGroup ||
            this.authService.isInManufacturerLicenceGroup ||
            isAdmin
          "
        >
          <button class="menu-button" mat-icon-button [matMenuTriggerFor]="platformmenu">
            <mat-icon>apps</mat-icon>
          </button>
          <mat-icon class="porter-wortmarke" svgIcon="porter-wortmarke"></mat-icon>
          <ng-container *ngIf="isInFloorplanEditor()">
            <span class="module-title">Grundrisse</span>
            <div class="info-label-black">BETA</div>
          </ng-container>
          <span class="module-title" *ngIf="isInConfiguration()">Bemusterung</span>
          <div class="link-row">
            <a class="hide" href="">Link 1</a>
            <a class="hide" href="">Link 2</a>
            <a class="hide" href="">Link 3</a>
          </div>

          <a class="more-link hide" [matMenuTriggerFor]="moreLinksMenu">
            Mehr<mat-icon>keyboard_arrow_down</mat-icon>
          </a>
        </ng-container>

        <ng-container *ngIf="isFingerhaus()">
          <div class="link-row fingerhaus-menu">
            <mat-icon class="fingerhaus-logo" svgIcon="fingerhaus-logo"></mat-icon>
            <a
              *ngIf="isProductCatalogAccessible()"
              routerLink="/produkt-katalog"
              routerLinkActive="is-active"
              >Produktkatalog</a
            >
            <a
              *ngIf="isProductManagementAccessible()"
              routerLink="/produkt-management"
              routerLinkActive="is-active"
              >Produktmanagement</a
            >
            <a
              *ngIf="isBuildingProjectsAccessible()"
              routerLink="/bauvorhaben"
              routerLinkActive="is-active"
              >Bauvorhaben</a
            >
          </div>
        </ng-container>
      </div>

      <mat-menu
        #moreLinksMenu="matMenu"
        class="dropdown dropdown-morelinks"
        [overlapTrigger]="false"
        xPosition="after"
      >
        <ul>
          <li class="hide"><a href="">Link 1</a></li>
          <li class="hide"><a href="">Link 2</a></li>
          <li class="hide"><a href="">Link 3</a></li>
        </ul>
      </mat-menu>

      <mat-menu
        #platformmenu="matMenu"
        class="dropdown dropdown-menuswitch"
        [overlapTrigger]="false"
        xPosition="after"
      >
        <div class="button-wrapper">
          <ng-container
            *ngIf="
              this.authService.isInAiLicenceGroup ||
              this.authService.isInPorterLicenceGroup ||
              isAdmin
            "
          >
            <button
              class="module-link"
              mat-menu-item
              [routerLink]="[routerFloorPlanLink]"
              routerLinkActive="is-active"
            >
              PORTER Grundrisse
              <div class="info-label-black">BETA</div>
            </button>
          </ng-container>
          <ng-container
            *ngIf="
              this.authService.isInPorterLicenceGroup || this.authService.isInStreamingLicenceGroup || isAdmin
            "
          >
            <a routerLink="/app-link" target="_blank">
              <button mat-menu-item class="module-link" routerLinkActive="is-active">
                PORTER Bemusterung
              </button>
            </a>
          </ng-container>
        </div>
        <hr class="trenner_line" />
        <div class="button-wrapper">
          <button mat-menu-item class="upgrade-link hide">
            <a target="_blank" href="https://www.grundrisse-digitalisieren.de/#preistabelle">
              <mat-icon>upgrade</mat-icon>Upgrade
            </a>
          </button>
          <button mat-menu-item class="support-link">
            <a href="https://support.porter.de" target="_blank">
              <div class="icon-btn-inner-wrapper">
                <mat-icon class="material-icons-outlined">help_center</mat-icon>
                <span>Help- & Supportcenter</span>
              </div>
            </a>
          </button>
        </div>
      </mat-menu>

      <!--   RIGHT SIDE   -->
      <div class="right-side">
        <!-- Help and Support Button -->
        <button
          *ngIf="shouldShowHelpAndSupportButton()"
          mat-button
          color="primary"
          class="support-link"
        >
          <a href="https://support.porter.de" target="_blank">
            <div class="icon-btn-inner-wrapper">
              <mat-icon class="material-icons-outlined">help_center</mat-icon>
              <span>Help- & Supportcenter</span>
            </div>
          </a>
        </button>

        <!-- Profile Button -->
        <button
          *ngIf="!hideProfileMenu"
          class="profile-menu-button"
          mat-button
          [matMenuTriggerFor]="menu"
        >
          <div class="icon-btn-inner-wrapper">
            <ng-container *ngIf="authenticated">
              <mat-icon class="user-icon">person</mat-icon>
              <span class="user-name">{{ companyName }}</span>
            </ng-container>
            <mat-icon class="keyboard_arrow_down">keyboard_arrow_down</mat-icon>
          </div>
        </button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-menu
    #menu="matMenu"
    class="dropdown dropdown-user"
    [overlapTrigger]="false"
    xPosition="before"
  >
    <button *ngIf="!authenticated" mat-menu-item routerLink="/login">Login</button>
    <ng-container *ngIf="authenticated">
      <button mat-menu-item class="user-profile">
        <mat-icon class="user-icon">person</mat-icon>
        <div class="user-info">
          <div class="user-name">{{ companyName }}</div>
          <div class="user-email">{{ email }}</div>
        </div>
      </button>
    </ng-container>
    <button
      *ngIf="authenticated"
      class="logout"
      mat-button
      color="accent"
      routerLink="/login"
      [queryParams]="{ logout: 'true' }"
      (click)="logout()"
    >
      Abmelden
    </button>
  </mat-menu>
</div>
