<div class="dropdown-container">
  <mat-select
    class="custom-mat-select"
    panelClass="custom-dropdown-panel"
    placeholder="{{ configuration.placeholder }}"
    (valueChange)="onSelectionChange($event)"
    [value]="selectedItem"
    [disabled]="configuration.isDisabled"
    disableOptionCentering
  >
    <mat-option *ngFor="let option of configuration.options" [value]="option">
      {{ option.label }}
    </mat-option>
  </mat-select>
  <mat-icon class="dropdown-icon">expand_more</mat-icon>
</div>
