import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ExporterPluginService } from './services/exporter-plugin.service';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ExportPluginGuard implements CanActivate {
  constructor(private exporterPluginService: ExporterPluginService, private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.getCurrentUser().pipe(
      map(user => {
        if (this.exporterPluginService.usesExport() || user.admin) {
          return true;
        }
        this.router.navigate(['/projects'], { queryParams: { returnUrl: window.location.pathname + window.location.search } });
        return false;
      }),
    );
  }
}
