import { Inject, Component, OnInit } from '@angular/core';
import { VersionService } from '../../services/version.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss'],
})
export class DownloadComponent implements OnInit {
  constructor(
    private versionService: VersionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.iconRegistry.addSvgIcon(
      'porter-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/porter-logo.svg')
    );
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  ngOnInit(): void {
    console.log('DownloadComponent ngOnInit()');
    this.getLatestVersion();
  }

  getLatestVersion() {
    return this.versionService.getLatestVersion().then(
      (res) => {
        this.document.location.href = res.path;
      },
      (error) => {
        console.log('Failed to navigate to link, with error: ' + error);
      }
    );
  }
}
