import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { EMPTY } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { AuthService } from '../../../services/auth.service';
import { StreamingService } from '../../../services/streaming.service';
import { FloorplanAIService } from '../../../services/floorplan-ai.service';
import { ExporterPluginService } from '../../../services/exporter-plugin.service';

import { environment } from '../../../../environments/environment';
import { User } from '../../../business-domain/User';
import { Alert } from '../../../directives/alert.directive';

const SUCCESSFUL_LOGOUT_ALERT: Alert = {
  type: 'success',
  collapseIcon: true,
  text: 'Sie haben sich erfolgreich abgemeldet',
};

const SESSION_TERMINATED_ALERT: Alert = {
  type: 'warning',
  collapseIcon: true,
  text: 'Ihre Session wurde beendet',
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  user: User;

  hidePw = true;
  invalidCredentials = false;
  duplicateUser = false;
  waitingForResponse = false;
  needsUserInput = false;
  logout = false;
  sessionLost = false;
  lastError = '';
  lastErrorHandled = true;

  usesExportPlugin = this.exporterPluginService.usesExport();
  customerSystem: String;

  constructor(
    private authService: AuthService,
    private streamingService: StreamingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private floorplanAiService: FloorplanAIService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private elRef: ElementRef,
    private exporterPluginService: ExporterPluginService,
    private titleService: Title
  ) {
    this.iconRegistry.addSvgIcon(
      'porter-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/porter-logo.svg')
    );
    this.iconRegistry.addSvgIcon(
      'fingerhaus-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fingerhaus-logo.svg')
    );
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  ngOnInit(): void {
    console.log('LoginComponent environment.customerSystem:', environment.customerSystem);
    this.customerSystem = environment.customerSystem;
    if (this.customerSystem == 'fingerhaus') {
      this.titleService.setTitle('FingerHaus Login');
    }

    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl(),
    });
    this.logout = this.activatedRoute.snapshot.queryParams.logout;
    this.sessionLost = this.activatedRoute.snapshot.queryParams.sessionLost;
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  login() {
    const { email, password } = this.form.value;

    if (!email || !password) {
      console.error('Missing parameters');
      return; // should never actually happen
    }
    this.waitingForResponse = true;

    this.authService
      .login(email, password)
      .pipe(
        switchMap(() => {
          if (this.routeIfPluginExport() || this.routeBasedOnReturnURL()) return EMPTY; //EMPTY emity complete notification, nex tis not triggered
          return this.authService.getCurrentUser();
        }),
        take(1) //unsing take(1) instead first, as it does not throw an error if completing without any emitted observable that is not empty
      )
      .subscribe(
        (user) => {
          this.user = user;
          this.authService.setUserGroups(this.user);
          console.log('LoginComponent this.user : ', this.user);
          this.routeBasedOnUser();
        },
        (error) => {
          console.error(error.error.message);
          if (
            error.status === 401 ||
            error.status === 403 ||
            error.error.message != 'NO_CONNECTION'
          ) {
            this.lastError = error.status == 403 ? 'USER_NOT_CONFIRMED' : 'WRONG_CREDENTIALS';
            this.lastErrorHandled = false;
            this.form.controls.email.setErrors({ incorrect: true });
            this.form.controls.password.setErrors({ wrong: true });
            this.invalidCredentials = true;
          } else if (error.error.message === 'NO_CONNECTION') {
            alert('Verbindung zum Server fehlgeschlagen');
          }
          this.waitingForResponse = false;
        }
      );
  }

  routeIfPluginExport(): boolean {
    // check for export plugin and redirect to projects
    if (this.exporterPluginService.usesExport()) {
      this.router.navigate(['/projects']);
      return true;
    }
    return false;
  }

  routeBasedOnReturnURL(): boolean {
    let returnUrl = this.getReturnUrl();

    // on Grundrisse route, go to last conversion if no conversionId is included in path already
    if (returnUrl.startsWith('/grundrisse') && !returnUrl.includes('conversionId')) {
      this.redirectToLatestUpload();
      return true;
    }

    // redirect if a valid returnUrl is set
    if (
      returnUrl != '/' &&
      !returnUrl.startsWith('/register') &&
      !returnUrl.startsWith('/confirmation')
    ) {
      this.router.navigateByUrl(returnUrl);
      return true;
    }
    return false;
  }

  routeBasedOnUser(): void {
    console.log('ROUTING BASED ON USER');
    // by default redirect user to dashboard
    // TODO: LicenceGroups are not yet available at this point, so the else part ist executed after login. After logout & direct "Re-Login" it works
    if (this.authService.isInAiLicenceGroup || this.user.admin) {
      console.log('You logged in with isInAiLicenceGroup');
      this.router.navigateByUrl('/grundrisse');
    } else if (this.authService.isInFingerhausProductManagementGroup) {
      console.log('You logged in with isInFingerhausProductManagementGroup');
      this.router.navigateByUrl('/produkt-management');
    } else if (this.authService.isInFingerhausAdminGroup) {
      console.log('You logged in with isInFingerhausAdminGroup');
      this.router.navigateByUrl('/benutzer-verwaltung');
    } else if (this.authService.isInFingerhausConsultantGroup) {
      console.log('You logged in with isInFingerhausConsultantGroup');
      this.router.navigateByUrl('/produkt-katalog');
    } else {
      console.log('You logged in with other LicenceGroup -> /app-link');
      this.router.navigateByUrl('/app-link');
    }
  }

  private getReturnUrl(): string {
    return this.activatedRoute.snapshot.queryParams.returnUrl &&
      !/^\/login/i.test(this.activatedRoute.snapshot.queryParams.returnUrl)
      ? this.activatedRoute.snapshot.queryParams.returnUrl
      : '/';
  }

  private clearErrors(): void {
    if (!this.lastErrorHandled) {
      this.lastErrorHandled = true;
      setTimeout(() => {
        this.clearErrors();
      }, 2000);
      return;
    }
    this.form.controls.email.setErrors(null);
    this.form.controls.email.updateValueAndValidity();
    this.form.controls.password.setErrors(null);
    this.form.controls.password.updateValueAndValidity();
    this.invalidCredentials = false;
  }

  redirectToLatestUpload() {
    this.floorplanAiService.getAllUserConversions().subscribe(
      (conversions) => {
        console.log('conversions: ', conversions);
        if (conversions.length === 0) {
          window.location.replace(
            window.location.origin +
              '/grundrisse/grundriss-editor?conversionId=&environment=' +
              environment.apiUrl
          );
          return;
        }

        conversions.reverse().forEach((conv) => {
          if (conv.status == 'COMPLETED' || conv.status === 'IN_PROGRESS') {
            window.location.replace(
              window.location.origin +
                '/grundrisse/grundriss-editor?conversionId=' +
                conv.conversionId +
                '&environment=' +
                environment.apiUrl
            );
            return;
          } else if (conv == conversions[conversions.length - 1]) {
            window.location.replace(
              window.location.origin +
                '/grundrisse/grundriss-editor?conversionId=&environment=' +
                environment.apiUrl
            );
            return;
          }
        });
      },
      (error) => {
        console.error(error.message);
      }
    );
  }

  getSuccessfulLogoutAlert(): Alert {
    return SUCCESSFUL_LOGOUT_ALERT;
  }

  getSessionTerminatedAlert(): Alert {
    return SESSION_TERMINATED_ALERT;
  }
}
