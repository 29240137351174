<div
  class="stream-dialog-container modal-m real-modal"
  [ngClass]="{ 'slim-dialog': !showingInfoBox }"
>
  <div class="modal-header w-100">
    <h2 class="modal-title">PORTER Cloud Solution</h2>
    <!-- Close Button-->
    <div *ngIf="isModal" class="close-button">
      <div *ngIf="dialogRef != null" mat-button>
        <mat-icon (click)="this.dialog.closeAll()">close</mat-icon>
      </div>
    </div>
  </div>
  <div class="modal-main">
    <ng-container *ngIf="!showingInfoBox">
      <div class="loading-image-container">
        <!-- <mat-spinner [color]="accent"></mat-spinner> -->
        <img class="loading-image" src="assets/Loading_Anim_light_blue.gif" />
      </div>
    </ng-container>
    <ng-container>
      <ng-container *ngIf="showingInfoBox">
        <p class="modal-subtitle" *ngIf="showingInfoBox">{{ data.text }}</p>
      </ng-container>
      <div [@.disabled]="disableAnimation">
        <!-- avoids initial jump of extension-panel, is material-bug #13870-->
        <mat-expansion-panel [expanded]="!oldStreamAvailable">
          <p class="modal-text">{{ data.durationInfo }}</p>
        </mat-expansion-panel>
      </div>
    </ng-container>
  </div>
  <div class="mt-0 mb-4">
    <mat-checkbox *ngIf="showingInfoBox" [(ngModel)]="doNotShowAgain"
      >Diesen Schritt zukünftig überspringen.
    </mat-checkbox>
  </div>
  <div class="dialog-actions">
    <div class="buttons">
      <button *ngIf="showingInfoBox" (click)="this.navigate()" mat-flat-button color="accent">
        Streaming Solution starten
      </button>
      <button
        mat-stroked-button
        class="cancel-button"
        color="accent"
        (click)="this.dialog.closeAll()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</div>
