export const APP_PATHS = {
  dashboard: 'dashboard',
  assetCatalog: 'produkt-katalog',
  onboardingScreen: 'onboarding-screen',
  assetCatalogOnly: 'produkt-katalog-only',
  assetManagement: 'produkt-management',
  buildingProjects: 'bauvorhaben',
  service: 'service',
  floorPlans: 'grundrisse',
  projects: 'projects',
  login: 'login',
  manufacturers: 'hersteller',
  register: 'register',
  reset: 'reset',
  // TODO: consider moving parameter to a child route
  confirmation: 'confirmation/:token',
  appLink: 'app-link',
  download: 'download',
  visualUnits: 'visual-units',
  webProtocol: 'web-protocol',
};

/*
TODO: Add the paths of the routed modules here in further constants
in order to manage them centrally here
*/
