import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';

import { AuthService, ErrorType } from '../../../services/auth.service';

import { passwordCheckValidator } from '../../../directives/password-check.directive';
import { requiredErrorValidator } from '../../../directives/required-error.directive';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  form: FormGroup;

  termsOfService = false;
  sent = false;
  hidePw = true;
  invalidCredentials = false;
  duplicateUser = false;
  waitingForResponse = false;

  @ViewChild('checkBox')
  checkBox: MatCheckbox;

  constructor(
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private elRef: ElementRef,
    private titleService: Title
  ) {
    this.iconRegistry.addSvgIcon(
      'porter-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/porter-logo.svg')
    );
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  ngOnInit(): void {
    this.form = new FormGroup(
      {
        company: new FormControl(),
        //username: new FormControl(),
        lastName: new FormControl(),
        firstName: new FormControl(),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl(),
        passwordCheck: new FormControl(),
        industry: new FormControl(),
        city: new FormControl(),
        // TODO: Validate number!!
        zipcode: new FormControl(),
        street: new FormControl(),
        houseNumber: new FormControl(),
        termsOfService: new FormControl(),
      },
      { validators: [passwordCheckValidator, requiredErrorValidator] }
    );
  }

  ngAfterViewInit(): void {
    // this.checkBox.ripple.radius = 15;
    // TODO: Make register container in height responsive with the signup-footer
    // var ElHeight = this.elRef.nativeElement.querySelector('.inner-container').offsetHeight;
    // var windowHeight = window.innerHeight;
    // console.log("element height: "+ ElHeight);
    // console.log("window height: "+ windowHeight);
  }

  get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  editRegUser() {
    this.duplicateUser = false;

    //this.removeIncorrectError(this.form.controls.username);
    this.removeIncorrectError(this.form.controls.email);
  }

  private removeIncorrectError(formControl: AbstractControl): void {
    for (const errorName in formControl.errors) {
      if (errorName === 'incorrect') {
        delete formControl.errors[errorName];
      }
    }

    if (formControl.errors && Object.keys(formControl.errors).length === 0)
      formControl.setErrors(null);
  }

  register() {
    if (
      !this.form.value.company ||
      //!this.form.value.username ||
      !this.form.value.email ||
      !this.form.value.password ||
      !this.form.value.passwordCheck ||
      !this.form.value.lastName ||
      !this.form.value.firstName
    ) {
      console.error('Missing parameters');
      return; // should never actually happen
    }
    if (this.form.controls.email.hasError('email')) {
      console.error('Invalid email');
      return; // feedback is displayed automatically
    }
    // TODO: password requirements?
    if (this.form.value.password !== this.form.value.passwordCheck) {
      console.log('passwords are different');
      this.form.controls.passwordCheck.setErrors({ incorrect: true });
      return;
    }

    this.waitingForResponse = true;
    this.authService.register(this.form.value).subscribe(
      (data) => {
        // Weiterleitung Payment
        console.log('You registered successfully!');
        this.sent = true;
        if (data.returnUrl) window.location = data.returnUrl;
      },
      (error) => {
        console.error(error.message);
        if (error === ErrorType.DUPLICATE_USER) {
          //this.form.controls.username.setErrors({ incorrect: true });
          this.form.controls.email.setErrors({ incorrect: true });
          this.duplicateUser = true;
        }
        this.waitingForResponse = false;
      },
      () => {
        console.log('complete registration');
        this.waitingForResponse = false;
      }
    );
  }
}
