import { Injectable } from '@angular/core';

export const SEPARATOR = '/';

/*
 * This service should only be used temporary. Instead the navigation service should be used,
 * which itself should rely on angular location to extract the query parameters.
 * For now the parameters we extract are route parameters, see navigation service comment - at the bottom.
 */
@Injectable({
  providedIn: 'root',
})
export class UrlService {
  constructor() {}

  getUrl(): string {
    return decodeURI(window.location.href);
  }

  getBaseUrl(): string {
    return document.location.origin;
  }

  urlContains(path: string): boolean {
    return this.getUrl().includes(path);
  }

  logUrl() {
    console.log('Url: ', this.getUrl());
  }

  // Bad solution. It does not handle the use of multiple occurrences of the key or if it is used as query parameter
  getRouteParameterValue(key: string): string {
    const url = this.getUrl();
    const keyWithSeparator = key + SEPARATOR;

    const startIndex = url.indexOf(keyWithSeparator);
    const keyEndIndex = startIndex + keyWithSeparator.length;
    const endIndex = url.indexOf(SEPARATOR, keyEndIndex);

    const containsKey = startIndex !== -1;
    const containsSeparatorAfterValue = endIndex !== -1;

    if (containsKey) {
      if (containsSeparatorAfterValue) {
        return url.substring(keyEndIndex, endIndex);
      } else {
        return url.substring(keyEndIndex);
      }
    } else {
      return '';
    }
  }

  removeRouteParameters(keys: string[]): string {
    let result = this.getUrl();

    for (const key of keys) {
      result = this.removeRouteParameter(result, key);
    }

    return result;
  }

  private removeRouteParameter(url: string, key: string): string {
    const keyWithSeparator = key + SEPARATOR;

    const startIndex = url.indexOf(keyWithSeparator);
    const keyEndIndex = startIndex + keyWithSeparator.length;
    const endIndex = url.indexOf(SEPARATOR, keyEndIndex);

    const containsKey = startIndex !== -1;
    const containsSeparatorAfterValue = endIndex !== -1;

    let value;
    let routeParameter;

    if (containsKey) {
      if (containsSeparatorAfterValue) {
        value = url.substring(keyEndIndex, endIndex);
        routeParameter = keyWithSeparator + value + SEPARATOR;
        url = url.replace(routeParameter, '');
      } else {
        value = url.substring(keyEndIndex);
        routeParameter = keyWithSeparator + value;
        url = url.replace(routeParameter, '');
      }
    }

    return url;
  }
}
