import { Component } from '@angular/core';
import { EnvironmentService } from '../../../../services/environment.service';

@Component({
  selector: 'test',
  template: '',
})
export class BaseComponent {
  constructor(protected environmentService: EnvironmentService) {}

  isFingerhaus(): boolean {
    return this.environmentService.isFingerhaus();
  }

  isPorter(): boolean {
    return this.environmentService.isPorter();
  }

  isEmbeddedAsIframe(): boolean {
    return window.self !== window.top;
  }
}
