import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Alert } from '../../directives/alert.directive';

const PREVIEW_ALERT: Alert = {
  type: 'info',
  text:
    'Hier können zukünftig Nutzer der Hersteller Lizenz die Anbindung Ihrer Produkte für die ' +
    'virtuelle Bemusterung, Statistiken zu Ihren Produkten, Auswertungen zu bemusterten Produkten ' +
    "und die wichtigsten KPI's (Key Performance Indicators) finden.",
};

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss'],
})
export class ManufacturerComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private titleService: Title,
    private activatedRoute: ActivatedRoute
  ) {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  isShowing: boolean;

  ngOnInit(): void {
    console.log('ManufacturerComponent ngOnInit()');

    this.isShowing = true;

    this.checkForMobile();
    this.renderer.listen(window, 'resize', () => this.checkForMobile());
  }

  checkForMobile() {
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    console.log('checkForMobile isShowing:' + width);

    //mat-sidenav mode
    if (width > 880) {
      return true;
    } else {
      return false;
    }
  }

  toggleSidenav() {
    this.isShowing = !this.isShowing;
    // console.log("toggleSidenav isShowing:"+this.isShowing);
  }

  getAlert(): Alert {
    return PREVIEW_ALERT;
  }
}
