import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NavigationService, Page } from './services/navigation.service';
import { AuthService } from './services/auth.service';
import { ConfigurationResolverService } from './resolvers/configuration-resolver.service';

export const LOCAL_STORAGE_ONBOARDING_SCREEN_VISIT_KEY = 'visitedAssetCatalog';

@Injectable()
export class OnboardingScreenGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private authService: AuthService,
    private configurationResolverService: ConfigurationResolverService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const onboardingScreenGroup: string[] = route.data.onboardingScreenGroup;
    const userIsInOnboardingUserGroup = await this.isUserInOnboardingUserGroups(
      onboardingScreenGroup
    );

    if (userIsInOnboardingUserGroup && this.isFirstVisit()) {
      this.handlePotentialConfigurationIdsFromURL(route);
      await this.redirectToOnboardingScreen();
      return false;
    }

    return true;
  }

  private isFirstVisit(): boolean {
    return this.localStorageDoesNotContainFirstVisitValue();
  }

  private localStorageDoesNotContainFirstVisitValue(): boolean {
    return !!!window.localStorage.getItem(LOCAL_STORAGE_ONBOARDING_SCREEN_VISIT_KEY);
  }

  private async redirectToOnboardingScreen() {
    await this.navigationService.navigateTo(Page.ONBOARDING_SCREEN);
  }

  private async isUserInOnboardingUserGroups(onboardingUserGroups: string[]): Promise<boolean> {
    const user = await this.authService.getCurrentUser().toPromise();
    return onboardingUserGroups.some((onboardingUserGroup) =>
      user.groups.includes(onboardingUserGroup)
    );
  }

  private handlePotentialConfigurationIdsFromURL(route: ActivatedRouteSnapshot) {
    this.configurationResolverService.resolve(route);
  }
}
