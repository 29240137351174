<div class="sidenav">
  <div class="button-wrapper button-wrapper-dashboard-home">
    <div class="dashboard-text">
      <mat-icon class="material-icons-outlined">dashboard</mat-icon>Dashboard
    </div>
  </div>

  <div class="button-wrapper">
    <ng-container
      *ngIf="
        this.authService.isInAiLicenceGroup || this.authService.isInPorterLicenceGroup || isAdmin
      "
    >
      <button
        class="module-link"
        mat-menu-item
        [routerLink]="[routerGrundrisseLink]"
        routerLinkActive="is-active"
      >
        PORTER Grundrisse
        <span class="info-label-black">BETA</span>
      </button>
    </ng-container>

    <ng-container
      *ngIf="
        this.authService.isInAiLicenceGroup || this.authService.isInStreamingLicenceGroup || this.authService.isInPorterLicenceGroup ||
        isAdmin
      "
    >
    <a [href]="routerAppLink" target="_blank">
      <button
        mat-menu-item
        class="module-link"
        routerLinkActive="is-active"
      >
        PORTER Bemusterung
      </button>
    </a>
    </ng-container>

  </div>
  <div class="frame-content">
    <div class="info-label-transparent mt-4 ml-4">BETA</div>
    <div class="frame-content-text">
      Wir laden Sie herzlich dazu ein, die BETA-Version unserer Grundriss Umwandlung (Basic-Paket)
      zu nutzen und Ihre Erfahrungen mit den neuen Funktionen mit uns zu teilen.
    </div>
    <div class="frame-content-text">
      Erfahren Sie mehr über die Beta-Phase und wie Sie daran teilnehmen können, indem Sie
      <a href="https://support.porter.de" style="color: #007ae6; text-decoration: underline"
        >hier</a
      >
      klicken.
    </div>
    <hr class="trenner_line" />
    <div>
      <button class="button-with-icon">
        <div class="icon-btn-inner-wrapper">
          <a
            href="https://porter-gmbh.atlassian.net/servicedesk/customer/portal/3/group/10/create/47"
            target="_blank"
            ><mat-icon class="material-icons-outlined">add_comment</mat-icon>Feedback geben</a
          >
        </div>
      </button>
    </div>
    <button class="button-with-icon">
      <div class="icon-btn-inner-wrapper">
        <a
          href="https://porter-gmbh.atlassian.net/servicedesk/customer/portal/3/group/10/create/49"
          target="_blank"
          ><mat-icon class="material-icons-outlined">bug_report</mat-icon>Fehler melden</a
        >
      </div>
    </button>
  </div>
  <div class="button-wrapper">
    <button mat-menu-item class="upgrade-link hide">
      <a target="_blank" href="https://www.grundrisse-digitalisieren.de/#preistabelle"
        ><mat-icon>upgrade</mat-icon> Upgrade</a
      >
    </button>
  </div>
</div>
