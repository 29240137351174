import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { APP_PATHS } from '../route-paths';

export enum Page {
  CATALOG,
  ONBOARDING_SCREEN,
  LIVING_STYLE_CONSULTANT,
  LIVING_STYLE_INFO,
}

export interface OnboardingScreenQueryParameters {
  livingStyleConsultant: 'open';
  livingStyleInfo: 'open';
}

const LIVING_STYLE_CONSULTANT_QUERY_PARAMETER = {
  livingStyleConsultant: 'open',
};

const LIVING_STYLE_INFO_QUERY_PARAMETER = {
  livingStyleInfo: 'open',
};

// CHAT GPT
// Use navigate()
// when you want to navigate to a route within your Angular application and construct the route path
// based on route configuration and parameters.
//
// Use navigateByUrl()
// when you want to navigate to an external URL or to a route using an absolute URL string.
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  async navigateTo(page: Page) {
    switch (page) {
      case Page.CATALOG:
        await this.navigate(APP_PATHS.assetCatalog);
        break;
      case Page.ONBOARDING_SCREEN:
        await this.navigate(APP_PATHS.onboardingScreen);
        break;
      case Page.LIVING_STYLE_CONSULTANT:
        await this.navigate(APP_PATHS.onboardingScreen, LIVING_STYLE_CONSULTANT_QUERY_PARAMETER);
        break;
      case Page.LIVING_STYLE_INFO:
        await this.navigate(APP_PATHS.onboardingScreen, LIVING_STYLE_INFO_QUERY_PARAMETER);
        break;
    }
  }

  async getBaseRouteSegment(activatedRoute: ActivatedRoute): Promise<string> {
    let pathFromRoot = await activatedRoute.pathFromRoot[1].url.pipe(first()).toPromise();
    return pathFromRoot[0].path;
  }

  getPathForPrecedingPage(path): string {
    const substring = 'seite=';
    const parameterIndex = path.indexOf(substring);
    const charIndex = parameterIndex + substring.length;
    const page = path.charAt(charIndex);
    const newPage = +page - 1;
    const newPath = path.substring(0, charIndex) + newPage + path.substring(charIndex + 1);
    return newPath;
  }

  isAssetCatalog(): boolean {
    return (
      this.router.url.includes(APP_PATHS.assetCatalog) ||
      this.router.url.includes(APP_PATHS.assetCatalogOnly)
    );
  }

  isAssetManagement(): boolean {
    return this.router.url.includes(APP_PATHS.assetManagement);
  }

  private async navigate(url: string, queryParameters?: any) {
    const navigationExtras: NavigationExtras = {
      queryParams: queryParameters,
    };

    await this.router.navigate([url], navigationExtras);
  }
}
