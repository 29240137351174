import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SchemasService implements OnDestroy {
  private schemaTreeSubscription: Subscription;
  private schemaTreeSubject: BehaviorSubject<any> = new BehaviorSubject({ data: [] });
  private schemaTreeSubscriptionFiltered: Subscription;
  private schemaTreeSubjectFiltered: BehaviorSubject<any> = new BehaviorSubject({ data: [] });
  private schemaTreeWithPropsSubject: BehaviorSubject<any> = new BehaviorSubject({ data: [] });
  private schemasSubject: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {}

  private initSchemas(): any {
    const availableSchemaTree = this.getAvailableSchemaTree();

    availableSchemaTree.subscribe((schemaTree: { data }) => {
      let schemas = this.getFlattenedSchemaTree(schemaTree.data);
      this.schemasSubject.next(schemas);
    });
  }

  getFlattenedSchemaTree(schemaTreeData): Array<any> {
    let schemas = [];

    aggregateChildren(schemas, schemaTreeData);

    return schemas;

    // kept scoped for now due to high specificity
    function aggregateChildren(array, schemas) {
      for (let i = 0; i < schemas.length; i++) {
        let schema = schemas[i];
        array.push(schema);

        if (schema.children) aggregateChildren(array, schema.children);
      }
    }
  }

  /*
  An unseccessary combination with getSchemaTree or getSchemaTreeWithProperties in a combineLatest might result in an additional request,
  because initSchemas() may not find any values in getschemaTreeSubject or getschemaTreeWithPropsSubject while their initialization didn't receive data yet.
  */
  getSchemas(): Observable<any> {
    //console.log('this.schemasSubject.getValue() = ', this.schemasSubject.getValue());
    if (!this.schemasSubject.getValue().length) this.initSchemas();
    //filter in order to only emit values containing a result from api vs the
    return this.schemasSubject.pipe(
      //tap((res) => console.log('Tap schemasSubject before filter: ', res)),
      filter((res) => res.length),
      first()
    );
  }

  getSchemaTree(filterByUser:boolean = false): Observable<any> {
    //console.log('this.schemasSubject.getValue() = ', this.schemasSubject.getValue());
    const subject = filterByUser ? this.schemaTreeSubjectFiltered : this.schemaTreeSubject;
    if (!subject.getValue().data.length) {
      filterByUser ? this.initSchemaTreeFiltered() : this.initSchemaTree();
    }

    //filter in order to only emit values containing a result from api vs the
    return subject.asObservable().pipe(
      //tap((res) => console.log('Tap schemasSubject before pipes: ', res)),
      filter((res) => res.data.length),
      map((res) => {
        let schemaTree = { data: [] };

        //get schemas nested in response to adapt to previously expected structure
        let expectedschemaTreeData = res['data']['0']['children'];
        schemaTree.data = expectedschemaTreeData;
        return schemaTree;
      }),
      //tap((res) => console.log('Tap schemasSubject after map: ', res)),
      first()
    );
  }

  getSchemaTreeWithProperties(): Promise<any> {
    let params = new HttpParams();

    params = params.append('includeProperties', 'true');

    /*
    TODO: Use with a global locale; de used as fallback without a set param
    params = params.append('lg', 'de');
    */

    let options = { params: params };
    return this.http
      .get(environment.apiUrl + '/schemas/tree', options)
      .pipe(
        map((res) => {
          this.schemaTreeWithPropsSubject.next(res);

          //get schemas nested in response to adapt to previously expected structure
          let schemaTree = { data: {} };
          let expectedschemaTreeData = res['data']['0']['children'];
          schemaTree.data = expectedschemaTreeData;

          //console.log('SCHEMATREEWITHPROPS: ', schemaTree);
          return schemaTree;
        })
      )
      .toPromise();
  }

  async getSchemaById(id): Promise<any> {
    let foundSchema;
    const schemas = await this.getSchemas().toPromise();

    foundSchema = schemas.find((schema) => {
      return schema.id == id;
    });
    return foundSchema;
  }

  async getChildrenIds(categoryId): Promise<string[]> {
    const schemaTree = await this.getSchemaTree().toPromise();
    const category = schemaTree.data.find((schema) => schema.id == categoryId);
    if (!category) return [];
    const childrenIds: string[] = [];
    category.children.forEach((child) => childrenIds.push(child.id));
    return childrenIds;
  }

  async getSchemaIdByName(schemaName): Promise<string> {
    let foundSchema;
    const schemas = await this.getSchemas().toPromise();

    foundSchema = schemas.find((schema) => {
      if (!schema.displayName) {
        return console.error('No displayName name found for category: ', schema);
      }
      return schema.displayName.toLowerCase() == schemaName.toLowerCase();
    });
    return foundSchema.id;
  }

  //check whether argument equals a schemaId of the "main schemas"(the schemas of the level directly below the root schema)
  async isAMainSchema(schemaId): Promise<boolean> {
    return this.getSchemaTree()
      .pipe(
        //tap((res) => console.log('res["data"]', res['data'])), //log trees main schemas
        map((res) => res['data'].some((mainSchema) => mainSchema['id'] == schemaId))
        //tap((res) => console.log('res in isAMainSchema: ', res)) //log results
      )
      .toPromise();
  }

  ngOnDestroy() {
    this.schemaTreeSubscription.unsubscribe();
    this.schemaTreeSubscriptionFiltered.unsubscribe();
  }

  private getAvailableSchemaTree(): Observable<any> {
    if (this.schemaTreeHasData()) {
      return this.schemaTreeSubject;
    } else if (this.schemaTreeWithPropertiesHasData()) {
      return this.schemaTreeWithPropsSubject;
    } else {
      return this.getSchemaTree();
    }
  }

  private subjectHasData(subject: BehaviorSubject<any>): boolean {
    return subject.getValue().data.length;
  }

  private schemaTreeHasData(): boolean {
    return this.subjectHasData(this.schemaTreeSubject);
  }

  private schemaTreeWithPropertiesHasData(): boolean {
    return this.subjectHasData(this.schemaTreeWithPropsSubject);
  }

  private initSchemaTree(): any {
    this.schemaTreeSubscription = this.http
      .get(environment.apiUrl + '/schemas/tree')
      .subscribe((schemaTree) => {
        //console.log('SCHEMATREE = ', schemaTree);
        this.schemaTreeSubject.next(schemaTree);
      });
  }

  private initSchemaTreeFiltered(): any {
    this.schemaTreeSubscriptionFiltered = this.http
      .get(environment.apiUrl + '/schemas/tree?filter=true')
      .subscribe((schemaTree) => {
        //console.log('SCHEMATREE = ', schemaTree);
        this.schemaTreeSubjectFiltered.next(schemaTree);
      });
  }

  /*  getSchemaById(schemaId: string): any {
  return this.http.get(environment.apiUrl + '/schemas/' + schemaId);
}

getSchemaTreeById(schemaId: string): any {
  return this.http.get(environment.apiUrl + '/schemas/' + schemaId + '/tree');
}  */

  // Needed by future function getSchemasWithProperties
  /* private async initSchemasWithProperties(): any {
    let availableTree: Observable<any>;
    //check for available tree data to avoid unnecessary request
    if (this.schemaTreeWithPropsSubject.getValue().data.length)
      availableTree = this.schemaTreeWithPropsSubject;
    else availableTree = await of(this.getSchemaTreeWithProperties());

    availableTree.subscribe((schemaTreeWithProperties: { data }) => {
      let schemas = this.getFlattenedSchemaTreeWithProperties(schemaTreeWithProperties.data);
      //console.log('SCHEMAS = ', schemas);
      this.schemasWithPropertiesSubj.next(schemas);
    });
  }

  // Needed by future function getSchemasWithProperties
  getFlattenedSchemaTreeWithProperties(schemaTreeData): Array<any> {
    let schemas = [];

    aggregateChildrenWithProperties(schemas, schemaTreeData);

    return schemas;

    // kept scoped for now due to high specificity
    function aggregateChildrenWithProperties(array, schemas) {
      for (let i = 0; i < schemas.length; i++) {
        let schema = schemas[i];
        let property = Object.entries(schema)
        this.schemaPropertiesService.parseDisplayNameWithLocale(property, this.locale);

        array.push(schema);

        if (schema.children) aggregateChildrenWithProperties(array, schema.children);
      }
    }
  } */
}
