import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../business-domain/User';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  user: User;
  isAdmin: Boolean;
  routerGrundrisseLink = '/grundrisse';
  routerAppLink = '/app-link';

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    console.log('SidebarComponent ngOnInit');

    this.authService.getCurrentUser().subscribe((user) => {
      this.user = user;
      this.isAdmin = this.user.admin;
      this.authService.setUserGroups(this.user);
    });
  }
  
  logout() {
    this.authService.logout();
  }
}
