<ng-container *ngIf="!usesExportPlugin">
  <header class="mat-typography"></header>
  <ng-container *ngIf="this.customerSystem === 'porter'">
    <a class="back-to-website row align-items-center" href="https://www.porter.de/" target="_blank">
      <mat-icon color="primary">arrow_back</mat-icon>
      <div>zurück zu <span>porter.de</span></div>
    </a>
  </ng-container>
  <ng-container *ngIf="this.customerSystem === 'fingerhaus'">
    <a
      class="back-to-website row align-items-center"
      href="https://www.fingerhaus.de/"
      target="_blank"
    >
      <mat-icon color="primary">arrow_back</mat-icon>
      <div class="FH-vertical-text-compensation">zurück zu <span>fingerhaus.de</span></div>
    </a>
  </ng-container>
</ng-container>

<div class="porter-container signup-container">
  <div *ngIf="authenticated && !waitingForResponse">
    <!-- redirect is too fast to see this -->
  </div>

  <div class="logo-container">
    <ng-container *ngIf="this.customerSystem == 'porter'">
      <mat-icon svgIcon="porter-logo" class="porter-icon"></mat-icon>
    </ng-container>
    <ng-container *ngIf="this.customerSystem == 'fingerhaus'" class="px-6">
      <mat-icon class="fingerhaus-logo" svgIcon="fingerhaus-logo"></mat-icon>
    </ng-container>
  </div>

  <div
    *ngIf="!authenticated || waitingForResponse"
    class="modal-s content-container color-bg-white p-6"
  >
    <div class="modal-header w-100 row">
      <h2 class="modal-title">Anmelden</h2>
    </div>

    <alert
      *ngIf="logout"
      class="logout-message"
      [type]="getSuccessfulLogoutAlert().type"
      [collapseIcon]="getSuccessfulLogoutAlert().collapseIcon"
      [text]="getSuccessfulLogoutAlert().text"
    ></alert>

    <alert
      *ngIf="sessionLost"
      class="logout-message"
      [type]="getSessionTerminatedAlert().type"
      [collapseIcon]="getSessionTerminatedAlert().collapseIcon"
      [text]="getSessionTerminatedAlert().text"
    ></alert>

    <form (ngSubmit)="login()" [formGroup]="form">
      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>E-Mail-Adresse eingeben</mat-label>
        <input
          matInput
          placeholder="E-Mail-Adresse eingeben"
          name="email"
          formControlName="email"
          required
          (keyup)="clearErrors()"
        />

        <mat-error *ngIf="form.controls.email.errors?.unknown" class="form-field-error"
          >E-Mail nicht registriert
        </mat-error>
        <mat-error
          *ngIf="!!form.controls.email.value && form.controls.email.errors?.email"
          class="form-field-error"
        >
          Ungültige E-Mail-Adresse
        </mat-error>
      </mat-form-field>

      <mat-form-field [hideRequiredMarker]="true" appearance="outline">
        <mat-label>Passwort eingeben</mat-label>

        <input
          matInput
          placeholder="Passwort eingeben"
          [type]="hidePw ? 'password' : 'text'"
          name="password"
          formControlName="password"
          required
          (keyup)="clearErrors()"
        />
        <mat-icon matSuffix class="hide-password material-icons" (click)="hidePw = !hidePw">{{
          hidePw ? 'visibility' : 'visibility_off'
        }}</mat-icon>

        <mat-error
          *ngIf="invalidCredentials && lastError != 'USER_NOT_CONFIRMED'"
          class="form-field-error"
          >E-Mail oder Passwort inkorrekt</mat-error
        >
        <mat-error
          *ngIf="invalidCredentials && lastError == 'USER_NOT_CONFIRMED'"
          class="form-field-error"
          >Ihr Account wurde noch nicht bestätigt</mat-error
        >
      </mat-form-field>
      <ng-container *ngIf="this.customerSystem == 'porter'">
        <div class="other-links">
          <!-- <mat-checkbox class="stay-loggedin">Angemeldet bleiben</mat-checkbox> -->

          <!-- <a routerLink="/register">Noch kein Konto? Jetzt registrieren!</a>  -->
          <a class="left" href="https://www.porter.de/kontakt/" target="_blank"
            >Sie haben noch keinen Account?</a
          >

          <a class="right" routerLink="/reset">Passwort vergessen?</a>
        </div>
      </ng-container>

      <div class="btn-container btn-container-w100">
        <button
          mat-flat-button
          color="accent"
          class="button-size-xl"
          [disabled]="form.invalid || waitingForResponse"
        >
          Anmelden
        </button>
      </div>
    </form>
  </div>
</div>
<ng-container *ngIf="this.customerSystem == 'porter'">
  <app-signup-footer *ngIf="!usesExportPlugin"></app-signup-footer>
</ng-container>
