import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogService } from '../../services/errordialog.service';
import { InfoboxDialogService } from '../../services/infobox-dialog.service';
import {
  DEFAULT_DROPDOWN_CONFIGURATION,
  DropdownConfiguration,
} from '../../shared/components/dropdown/dropdown.component';

@Component({
  selector: 'app-visual-units',
  templateUrl: './visual-units.component.html',
  styleUrls: ['./visual-units.component.scss'],
})
export class VisualUnitsComponent {
  showToggle = false;

  dropdownConfiguration_1: DropdownConfiguration;
  dropdownConfiguration_2: DropdownConfiguration;
  dropdownConfiguration_3: DropdownConfiguration;

  constructor(
    public dialog: MatDialog,
    private infoboxService: InfoboxDialogService,
    private errorDialogService: ErrorDialogService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  ngOnInit() {
    this.initializeDropdowns();
  }

  private async initializeDropdowns() {
    const options_1 = [
      { label: 'Keine', onClick: () => {} },
      { label: 'Biene', onClick: () => {} },
      { label: 'Hummel', onClick: () => {} },
      { label: 'Wespe', onClick: () => {} },
    ];

    const options_2 = [
      {
        label: 'this is a very very long example name for an option',
        onClick: () => {},
      },
      { label: 'two', onClick: () => {} },
      { label: 'three', onClick: () => {} },
      { label: 'four', onClick: () => {} },
      { label: 'five', onClick: () => {} },
      { label: 'six', onClick: () => {} },
      { label: 'seven', onClick: () => {} },
      { label: 'eight', onClick: () => {} },
      { label: 'nine', onClick: () => {} },
      { label: 'ten', onClick: () => {} },
      { label: 'eleven', onClick: () => {} },
    ];

    this.dropdownConfiguration_1 = {
      ...DEFAULT_DROPDOWN_CONFIGURATION,
      options: options_1,
    };

    this.dropdownConfiguration_2 = {
      ...DEFAULT_DROPDOWN_CONFIGURATION,
      options: options_2,
    };

    this.dropdownConfiguration_3 = {
      ...DEFAULT_DROPDOWN_CONFIGURATION,
      isDisabled: true,
      options: options_1,
    };
  }

  code_matbutton = '<button mat-button>Basic mat-button</button>';
  code_matflatbutton = '<button mat-flat-button>Basic mat-flat-button</button>';
  code_matstrokedbutton = '<button mat-stroked-button>Basic mat-stroked-button</button>';
  code_matflatbutton_attributecolors_accent =
    '<button mat-flat-button color="accent">Accent</button>';
  code_matflatbutton_attributecolors_primary =
    '<button mat-flat-button color="primary">Primary</button>';
  code_matflatbutton_icon =
    '<button mat-flat-button color="accent"><mat-icon class="material-icons">add</mat-icon>Accent</button>';
  code_maticonbutton =
    '<button mat-icon-button color="accent"><mat-icon class="material-icons">add</mat-icon>Accent</button>';
  code_maticonbutton_bg =
    '<button mat-icon-button class="icon-button-bg" color="accent"><mat-icon class="material-icons">add</mat-icon>Accent</button>';
  code_matbutton_size =
    '<button mat-flat-button class="button-size-xl" color="accent">XL Button</button>';

  openDialog() {
    this.dialog.open(ExampleModal, {
      width: 'auto',
      autoFocus: false,
      //: 'no-padding-dialog',
    });
  }

  toggleState() {
    this.showToggle = !this.showToggle;
  }

  openInfoboxDialog() {
    const options = {
      icon: 'language',
      title: 'PORTER Grundrisse',
      text: 'Die PORTER-Anwendung für Ihren Windows-PC.',
      content: '<ul><li>Info 1</li><li>Info 2</li>',
      buttonText: 'Optional Button', // buttonText.length determines if botton is visible
    };

    this.infoboxService.open(options);

    this.infoboxService.confirmed().subscribe((confirmed) => {
      //optional confirmation subscription
    });
  }

  openErrorDialog() {
    this.errorDialogService.openDialog({
      errorCode: '0xFFFFFF',
      location: 'floorplan-ai-service',
      devinfo: 'res.status === FAILED' /* Standard (falls verfügbar): error.message */,
      publicinfo:
        'Der Grundriss konnte nicht verarbeitet werden. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
      debug: false /* Standardwert, nur für Entwickler zur Fehlersuche true */,
      autoFocus: false /* Standardwert */,
    });
  }
}

@Component({
  selector: 'example-modal',
  templateUrl: './helpers/example-modal/example-modal.html',
  styleUrls: ['./helpers/example-modal/example-modal.scss'],
})
export class ExampleModal {
  constructor(public dialog: MatDialog) {}

  closeDialog() {
    this.dialog.closeAll();
  }
}

/* @Component({
  selector: 'example-input-with-selector',
  templateUrl: './helpers/example-input-with-selector/example-input-with-selector.html',
  styleUrls: ['./helpers/example-input-with-selector/example-input-with-selector.scss'],
})
export class ExampleInputWithUnitSelector implements OnInit {
  referenceValue: any;
  exampleUnits: any[] = [
    { value: 100, viewValue: 'cm' },
    { value: 1, viewValue: 'm' },
  ];

  formGroup: FormGroup;

  constructor() { }

  ngOnInit() {
  //set up form for wall height and attach it to floorplan object
    this.setFileForm();
  }

  setFileForm() {
    this.formGroup = new FormGroup(
      {
        inputValue: new FormControl(123),
        selectorValue: new FormControl('defaultValue', Validators.required),
      },

    );
  }


  //Validator to check if input value and mat-select value are valid in their combination

  crossFieldValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputVal = control.get('inputValue').value;
      const selectorVal = control.get('selectorValue').value;

      if (!inputVal) {
        return null;
      }
      let someValidityCondition = (inputVal * selectorVal) > 5;

      return someValidityCondition ? null : { belowMinHeight: true };
    };
  }


  onChange($event) { };

  onInputChange($event) { };

}
 */
