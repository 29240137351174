import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

const LOCALSTORAGE_HISTORY = 'navigationHistory';
const MAX_NAVIGATION_HISTORY_LENGTH = 3;

/*
 * We handle our history here manually. This can also be done by using angular location
 * which has built in functionality to handle the history.
 *
 * Document.location examples:
 * https://stackoverflow.com/questions/37517183/how-do-i-get-the-absolute-path-of-the-current-page-in-angular-2
 */
@Injectable({ providedIn: 'root' })
export class NavigationHistoryService {
  private history: string[] = [];

  constructor(private router: Router) {}

  init() {
    const initialURL = decodeURI(this.router.url);
    if (this.localStorageHasHistory()) {
      this.history = this.getHistoryFromLocalStorage();
    }

    if (!this.hasHistory() || this.isPathDifferentFromLastOne(initialURL)) {
      if (this.hasHistoryReachedMaxLength()) this.history.shift();
      this.history.push(initialURL);
      this.updateHistoryInLocalStorage(this.history);
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => decodeURI(event.urlAfterRedirects)),
        filter(
          (path: string) =>
            !this.isRedirectWithPage1QueryParams(path) && this.isPathDifferentFromLastOne(path)
        )
      )
      .subscribe((path: string) => {
        if (this.hasHistoryReachedMaxLength()) this.history.shift();
        this.history.push(path);
        this.updateHistoryInLocalStorage(this.history);
      });
  }

  getLastRoute(): string | null {
    if (this.hasHistory()) {
      this.history.pop(); //remove current route
      return this.history.pop(); //get previous path and remove it from history
    }
    return null;
  }

  getLastRouteIncludingString(pathFragment: string): string | null {
    if (this.hasHistory()) {
      const reverseHistory = [...this.history].reverse();
      reverseHistory.shift(); //remove current route
      return reverseHistory.find((path) => path.includes(pathFragment));
    }
    return null;
  }

  getHistory(): string[] {
    return this.history;
  }

  hasHistory(): boolean {
    return this.history.length > 1;
  }

  localStorageHasHistory(): boolean {
    const entry: string[] = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_HISTORY));
    return entry !== null && Array.isArray(entry) && entry.length > 0;
  }

  hasPreviousNavigation(): boolean {
    return this.history.length > 1;
  }

  getPreviousUrl(): string {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2];
    }
    return '';
  }

  /**
    Avoids de facto duplicate entries in history when paths to categories are
    programatically redirected by appending the query param "?seite=". The redirect to
    paths with query params cannot be done in the routing modules, therefore these redirects
    arent excluded by using event.urlAfterRedirects
  */
  private isRedirectWithPage1QueryParams(path: string) {
    return this.getLastPath() + '?seite=1' == path;
  }

  private isPathDifferentFromLastOne(path: string) {
    return this.getLastPath() !== path;
  }

  private hasHistoryReachedMaxLength() {
    return this.history.length >= MAX_NAVIGATION_HISTORY_LENGTH;
  }

  private getLastPath() {
    return this.history[this.history.length - 1];
  }

  private getHistoryFromLocalStorage(): string[] {
    let history = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_HISTORY));
    return history;
  }

  private updateHistoryInLocalStorage(history: string[]) {
    window.localStorage.setItem(LOCALSTORAGE_HISTORY, JSON.stringify(history));
  }
}
