import { Injectable } from '@angular/core';

declare const ue: {
  interface: {
    broadcast(name: string, data?: any): void;
    // callFromUnreal(path: any):void;
  };
};

declare const ue4: typeof ue.interface.broadcast;

@Injectable({
  providedIn: 'root',
})
export class UnrealService {
  constructor() {
    this.setupUnrealCalls();
  }

  private setupUnrealCalls() {
    // To call this method from Unreal you only have to use the WebInterface.call method and provide the method-name
    // as the first parameter. Here the name would be "callFromUnreal".
    // The Data you want to send from Unreal must be parsed first to a JSON value.
    // ue.interface.callFromUnreal = async (data: any) => {
    // What should happen when called from Unreal
    // };
  }

  beforeDialogOpen() {
    ue4('before_dialog_open');
  }

  afterDialogOpen() {
    ue4('after_dialog_open');
  }

  emitFavoriteAdded(assetId: string, preselectionId: string) {
    ue4('favorite_added', { assetId: assetId, preselectionId: preselectionId });
  }

  emitFavoriteDeleted(preselectionId: string) {
    ue4('favorite_deleted', { preselectionId: preselectionId });
  }

  emitFavoritesDeletedBySchema(schemaId: string) {
    ue4('favorites_deleted_by_schema', { schemaId: schemaId });
  }

  emitGoogleAnalyticsEvent(event) {
    ue4('send_to_google_analytics', { event: event });
  }

  emitExcelExportEventToUnreal(configurationId: string) {
    ue4('open_excel_export', { configurationId: configurationId });
  }

  emitJumpToActor(actorSlotId: string, actorId: string) {
    ue4('jump_to_actor', { actorSlotId: actorSlotId, actorId: actorId });
  }
}
