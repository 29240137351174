import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogConfig,
} from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TokenService } from '../../../services/token.service';
import { UnitService } from '../../../services/unit.service';
import { StreamingService } from '../../../services/streaming.service';
import { Stream } from '../../../business-domain/Stream';
import { SafeUrl } from '@angular/platform-browser';
import { ErrorDialogService } from '../../../services/errordialog.service';
import { delay, first, map, tap } from 'rxjs/operators';

export interface DialogData {
  text: string;
  durationInfo: string;
}

@Component({
  selector: 'stream-dialog',
  templateUrl: './stream-dialog.component.html',
  styleUrls: ['./stream-dialog.component.scss'],
})
export class StreamDialogComponent implements OnInit, AfterViewInit {
  @Input()
  unitId: BehaviorSubject<string>;
  @Input()
  instantLaunch = true;

  @Output()
  change: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('streamingWindow')
  streamingWindow: ElementRef;

  public isModal = false;
  public showingInfoBox = true;
  public showingFirstDialog = undefined;
  public streamLoading = false;
  public waitingForPing = true;
  public streamUrl: SafeUrl;
  public oldStreamAvailable = true;
  private session: Stream;
  private pingStreamSubscription: Subscription;
  public hasStreamAccess: boolean;
  private projectId: string;

  private standardDelay: number = 2600;
  private waitTimeInfoDelay: number = 1500;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // 87, 65, 83, 68,
    const keycodes = [37, 38, 39, 40];
    if (keycodes.includes(event.keyCode)) {
      this.setStreamingFocus();
    }
  }

  dataStartStream = {
    text: 'Wollen Sie wirklich die PORTER Streaming Solution starten?',
    durationInfo:
      'Ihr Projekt wird für Sie nach dem Start in etwa einer Minute zur Verfügung stehen.',
  };

  dataStreamLoading = {
    text: '',
    durationInfo: 'Sie haben in etwa einer Minute Zugriff auf die PORTER Streaming Solution.',
  };

  _doNotShowAgain = false;
  get doNotShowAgain(): boolean {
    return false;
  }

  set doNotShowAgain(value: boolean) {
    this.streamingService.setShowStartStreamDialog(!value);
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private streamingService: StreamingService,
    public dialogRef: MatDialogRef<StreamDialogComponent>,
    private unitService: UnitService,
    private tokenService: TokenService,
    private errorDialogService: ErrorDialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.oldStreamAvailable = true;
    this.waitingForPing = true;

    if (this.dialogRef != null) {
      this.dialogRef.disableClose = false;
      this.isModal = true;
    }

    this.showingFirstDialog = this.streamingService.getShowStreamingDialog(this.showingInfoBox);
    if (!this.showingFirstDialog) this.showingInfoBox = false;
    this.setDialogData();
    this.waitingForPing = true;
    this.oldStreamAvailable = await this.streamingService.awaitTestingStreamServer(
      this.waitTimeInfoDelay
    );
    this.waitingForPing = false;
    if (!this.showingFirstDialog) this.openConnection();
    this.setDialogData();
  }

  ngOnDestroy() {
    this.stopStreaming();
    this.dialog.closeAll();
  }

  // Workaround for angular component issue #13870 - remove if content of <div [@.disabled]="disableAnimation"> is deleted
  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid 'ExpressionChangedAfterItHasBeenCheckedError', is part of workaround for angular issue #13870
    setTimeout(() => (this.disableAnimation = false));
  }

  public setDialogData(): void {
    this.showingInfoBox ? (this.data = this.dataStartStream) : (this.data = this.dataStreamLoading);
  }

  private setStreamingFocus() {
    this.streamingWindow.nativeElement.contentWindow.focus();
  }

  changeNavigationControl(navigationKeyword: String) {
    this.setStreamingFocus();
    this.streamingWindow.nativeElement.contentWindow.postMessage(
      this.streamingService.createMessageByAction(navigationKeyword),
      '*'
    );
  }

  static options(): MatDialogConfig {
    return {
      width: 'auto',
      autoFocus: false,
      panelClass: 'no-padding-dialog',
    };
  }

  public navigate(scope?: any, router?: Router, route?: ActivatedRoute) {
    if (this.showingInfoBox) {
      this.showingInfoBox = false;
      this.setDialogData();
      this.openConnection();
    } else {
      this.stopStreaming();
      this.dialog.closeAll();
    }
  }

  async stopStreaming() {
    this.change.emit(false);
    this.streamLoading = false;
    if (this.pingStreamSubscription) this.pingStreamSubscription.unsubscribe();
    if (this.session) {
      this.session = undefined;
    }
  }

  generateStreamUrl(
    publicIP: string,
    unitId?: string,
    projectId?: string,
    levelId?: string
  ): string {
    const token = this.tokenService.getToken();
    let url: string;

    url =
      'http://' + publicIP + '/PixelDemo.htm?token=' + token.value + '&tokenexpiry=' + token.expiry;

    if (unitId && projectId && levelId) {
      url += '&projectid=' + projectId + '&unitid=' + unitId + '&levelid=' + levelId;
    }
    return url;
  }

  openConnection(unitId?: string, projectId?: string, levelId?: string) {
    let url: string;
    this.dialogRef.disableClose = true;
    this.streamLoading = true;
    this.streamingService.testStreamServer(1).subscribe(
      async (oldStream) => {
        if (oldStream && oldStream.status === 'running') {
          let delayTime =
            this.standardDelay - (this.showingFirstDialog ? 0 : this.waitTimeInfoDelay);
          this.session = oldStream;
          url = this.generateStreamUrl(oldStream.publicIP, unitId, projectId, levelId);
          await new Promise((resolve) => setTimeout(resolve, delayTime)); //keep to avoid loading dialog showing for a fraction of a second before opening stream
          if (this.streamLoading) this.openStream(url);
          this.dialog.closeAll();
        } else {
          if (!oldStream) {
            var session = await this.streamingService.startStreaming().toPromise();
          }
          this.session = oldStream ? oldStream : session;
          return (this.pingStreamSubscription = this.streamingService
            .testStreamServer(30)
            .subscribe(
              (newStream) => {
                this.session = newStream;
                if (newStream && newStream.status === 'running') {
                  url = this.generateStreamUrl(newStream.publicIP, unitId, projectId, levelId);
                  if (this.streamLoading) this.openStream(url);
                } else {
                  this.errorDialogService.openDialog({
                    errorCode: '0xFFFFFF',
                    location: 'stream-dialog - openConnection',
                    devinfo: 'newStream.status !== "running"',
                    publicinfo:
                      'Stream ist aktuell nicht verfügbar. Bitte versuchen Sie in ein paar Minuten die Seite neu zu laden. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
                    debug: false,
                    autoFocus: false,
                  });
                  this.stopStreaming();
                }
                this.dialog.closeAll();
              },
              (error) => {
                console.error(error);
                this.dialog.closeAll();
              }
            ));
        }
        this.dialog.closeAll();
      },
      (error) => {
        console.error(error);
        this.dialog.closeAll();
      }
    );
  }

  openStream(url: string) {
    window.open(url, '_blank');
  }

  reset() {
    this.streamingWindow.nativeElement.contentWindow.postMessage(
      this.streamingService.createMessageByAction('3D_RESET'),
      '*'
    );
  }
}
