import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent implements OnInit {
  sent: boolean;
  // email: string;
  emailCtrl = new FormControl('', [Validators.required, Validators.email]);
  waitingForResponse = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {
    this.iconRegistry.addSvgIcon(
      'porter-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/porter-logo.svg')
    );
    this.titleService.setTitle(this.activatedRoute.snapshot.data['breadcrumb']);
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigateByUrl('/');
    }
  }

  requestPasswordReset(): void {
    if (!this.emailCtrl) {
      console.error('Missing parameters');
      return; // should never actually happen
    }
    if (this.emailCtrl.hasError('email')) {
      console.error('Invalid email');
      return; // feedback is displayed automatically
    }

    this.waitingForResponse = true;
    this.authService.requestPasswordReset(this.emailCtrl.value).subscribe(
      () => {
        console.log('Reset request was sent');
        this.sent = true;
      },
      (error) => {
        console.error(error);
        if (error.error.message === 'USER_NOT_FOUND') this.emailCtrl.setErrors({ unknown: true });
        else this.emailCtrl.setErrors({ incorrect: true });

        this.waitingForResponse = false;
      }
    );
  }
}
