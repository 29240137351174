import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorDialogService } from './errordialog.service';
import { UrlService } from './url.service';

export interface Configuration {
  name: string;
  id: string;
  userId: string;
  consultantUserId: string;
  levelId: string;
  levelName: string;
  accessCode: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  contractSignDate: string;
  lockEmailSent: any;
  consultantData: any;
  customerData: any;
  bvhData: any; // TODO Improvement: This should not come from the Backend as a abbreviation - especially since it is german => BauvorhabenData
  bvhNumber: number;
  isLocked: number; // TODO Improvement: 0 ad 1 - should come as a boolean from the Backend
  isStyle: number; // TODO Improvement: 0 ad 1 - should come as a boolean from the Backend
  clientAppointmentDate: string;
  actors: any;
  actorSlots: any;
  projectStage: 'RELEASE' | 'BETA' | undefined;
}

export const ROUTE_PARAMETER_CONFIGURATION_ID_KEY = 'configurationId';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  readonly configuration$: Observable<Configuration>;
  private readonly configurationSubject: BehaviorSubject<Configuration>;

  constructor(
    private http: HttpClient,
    private errorDialogService: ErrorDialogService,
    private urlService: UrlService
  ) {
    this.configurationSubject = new BehaviorSubject<Configuration>(null);
    this.configuration$ = this.configurationSubject
      .asObservable()
      .pipe(filter((configuration) => !!configuration));
  }

  async updateConfigurationById(id: string): Promise<Configuration> {
    return this.getConfigurationByIdOrAccessCode(id)
      .pipe(
        tap((configuration: Configuration) => {
          this.setConfiguration(configuration);
        })
      )
      .toPromise();
  }

  async setConfigurationByAccessCode(accessCode: string): Promise<Configuration> {
    return this.getConfigurationByCode(accessCode)
      .pipe(
        tap((configuration: Configuration) => {
          this.setConfiguration(configuration);
        })
      )
      .toPromise();
  }

  async updateConfigurationByIdFromRouteParam() {
    const id = this.getConfigurationIdFromRouteParam();
    await this.updateConfigurationById(id);
  }

  updateConfiguration(configurationId: string, data: any) {
    this.http
      .put(environment.apiUrl + '/configurations/' + configurationId, {
        ...data,
      })
      .subscribe(
        () => {},
        (error) => {
          console.log('Error: ', error);
          console.log('updateConfiguration Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'configurations.service - updateConfiguration',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Speichern der Protokoll-Daten. Bitte laden Sie die Seite neu und versuchen es erneut. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        }
      );
  }

  async initBaseConfiguration() {
    if(this.configuration) {
      return;
    }
    const configuration = await this.getBaseConfiguration();
    this.setConfiguration(configuration);
  }

  // load actor and actorSlot data for the configuration including the asset details
  loadConfigurationDetails(): Observable<Configuration> {
    return this.http
      .get(environment.apiUrl + '/configurations/' + this.configuration.id + '/load?includeAssets=true')
      .pipe(map((res: any) => res.data));
  }

  getExcelExportForConfigurationId(configurationId: string) {
    this.http
      .get(environment.apiUrl + `/configurations/` + configurationId + '/excelExport', {
        responseType: 'blob',
      })
      .subscribe(
        (data) => {
          console.log('getExcelExportForConfigurationId Success: ', data);
          let url = window.URL.createObjectURL(data);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = 'protokoll-export.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        },
        (error) => {
          console.log('Error: ', error);
          console.log('getExcelExportForConfigurationId Error: ', error);
          this.errorDialogService.openDialog({
            errorCode: '0xFFFFFF',
            location: 'configurations.service - getExcelExportForConfigurationId',
            devinfo: error.message,
            publicinfo:
              'Fehler beim Herunterladen der Excel Datei. Bitte laden Sie die Seite neu und versuchen es erneut. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
            debug: false,
            autoFocus: false,
          });
        }
      );
  }

  async createPreselectionForConfiguration(assetId: string): Promise<string> {
    const url = environment.apiUrl + '/configurations/' + this.configuration.id + '/preselections';
    const body = { assetId: assetId };
    const result = await this.http.post(url, body).toPromise() as any;
    return result.id;
  }

  deletePreselectionById(preselectionId: string): Observable<any> {
    console.log('ConfigurationService deletePreselectionById(): ', preselectionId);

    return this.http.delete(environment.apiUrl + '/preselections/' + preselectionId, {});
  }

  deletePreselectionsForSchemaAndConfiguration(schemaId): Observable<any> {
    return this.http.delete(
      environment.apiUrl +
        '/schemas/' +
        schemaId +
        '/configurations/' +
        this.configuration.id +
        '/preselections/',
      {}
    );
  }

  getFavorites(configurationId: string): Observable<any> {
    return this.http
      .get(environment.apiUrl + '/configurations/' + configurationId + '/preselections')
      .pipe(map((result: any) => result.data));
  }

  async updatePreselectionNote(preselectionId: string, note: string) {
    return this.http
      .put(environment.apiUrl + '/preselections/' + preselectionId, { note: note })
      .toPromise()
      .catch((error) => {
        console.error(error.message);
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'configurations.service - updatePreselectionNote',
          devinfo: error.message,
          publicinfo: 'Beim Speichern der Notiz ist ein Fehler aufgetreten.',
          debug: false,
          autoFocus: false,
        });
      });
  }

  async updateActorNote(actorId: string, configurationId: string, note: string) {
    return this.http
      .put(
        environment.apiUrl +
          '/configurations/' +
          configurationId +
          '/actorObjectConfigurations/' +
          actorId,
        { note: note }
      )
      .toPromise()
      .catch((error) => {
        console.error(error.message);
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'configurations.service - updateActorNote',
          devinfo: error.message,
          publicinfo: 'Beim Speichern der Notiz ist ein Fehler aufgetreten.',
          debug: false,
          autoFocus: false,
        });
      });
  }

  async updateActorSlotNote(actorSlotId: string, configurationId: string, note: string) {
    return this.http
      .put(
        environment.apiUrl +
          '/configurations/' +
          configurationId +
          '/actorSlotMaterialConfiguration/' +
          actorSlotId,
        { note: note }
      )
      .toPromise()
      .catch((error) => {
        console.error(error.message);
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'configurations.service - updateActorSlotNote',
          devinfo: error.message,
          publicinfo: 'Beim Speichern der Notiz ist ein Fehler aufgetreten.',
          debug: false,
          autoFocus: false,
        });
      });
  }

  urlContainsIdAsRouteParameter(): boolean {
    return this.urlService.urlContains(ROUTE_PARAMETER_CONFIGURATION_ID_KEY);
  }

  getConfigurationIdFromRouteParam(): string {
    return this.urlService.getRouteParameterValue(ROUTE_PARAMETER_CONFIGURATION_ID_KEY);
  }

  private get configuration(): Configuration {
    return this.configurationSubject.getValue();
  }

  private getConfigurationByIdOrAccessCode(idOrAccessCode): Observable<any> {
    return this.http.get(environment.apiUrl + '/configurations/' + idOrAccessCode);
  }

  private getConfigurationByCode(code): Observable<Configuration> {
    return this.http.get<Configuration>(
      environment.apiUrl + '/configurations/configurationCode/' + code
    );
  }

  private setConfiguration(configuration: Configuration) {
    this.configurationSubject.next(configuration);
  }

  private async getBaseConfiguration(): Promise<Configuration> {
      const response = await this.http.get(environment.apiUrl + '/users/configuration').toPromise();
      return response as Configuration;
  }
}
