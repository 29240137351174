import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { User } from '../../../business-domain/User';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssetService } from '../../../services/asset.service';
import { AuthService } from '../../../services/auth.service';
import { AssetSearchResult } from '../models/asset-search-result';

@Injectable({
  providedIn: 'root',
})
export class ReadyNumberSearchHandlerService {
  user: User;

  //satic values concerning search for ready number
  readonly READY_NUMBER_LENGTH = 5;
  readonly PARTNER_TAG_SHORTCUT = '%';
  readonly READY_NUMBER_PARAM_NAME = 'readyNumber';

  // info results
  readonly INVALID_READY_NUMBER_INFO: AssetSearchResult = {
    info: {
      icon: 'error',
      iconColor: 'warn',
      title: 'Die eingegebene PORTER-Ready-Nummer ist nicht valide',
      text: 'Eine gültige Eingabe sieht wie folgt aus: <br> PRN-XXXXX (mit X als Ziffern zwischen 0-9, z.B. PRN-12345)',
    },
  };

  readonly NO_RESULT_FOR_READY_NUMBER_INFO: AssetSearchResult = {
    info: {
      icon: 'error',
      iconColor: 'warn',
      title: 'Die eingegebene PORTER-Ready-Nummer konnte nicht gefunden werden',
      text: 'Bitte überprüfen Sie Ihre Eingabe oder wenden Sie sich an unseren Support',
    },
  };

  constructor(private authService: AuthService, private assetService: AssetService) {
    //TODO: refactor, user should be a global object
    this.subscribeToUser();
  }

  doesTermStartWithPartnerTagSegment(term): boolean {
    if (term && this.user?.partnerTag) {
      const termStartsWithPartnerTag = term.startsWith(this.user.partnerTag);
      return term && (termStartsWithPartnerTag || this.doesTermStartWithPartnerTagShortcut(term));
    }
    
    return false;
  }

  doesTermStartWithPartnerTagShortcut(term: string): boolean {
    return term.startsWith(this.PARTNER_TAG_SHORTCUT);
  }

  getResultForReadyNumberInput(searchTerm: string): Observable<AssetSearchResult> {
    if (this.termIsValidReadyNumberWithPartnerTag(searchTerm)) {
      return this.searchByReadyNumberOnly(searchTerm);
    } else if (this.termIsValidReadyNumberWithPartnerTagShortcut(searchTerm)) {
      return this.searchByReadyNumberOnly(this.getTermWithShortcutReplacedByPartnerTag(searchTerm));
    } else {
      return of(this.INVALID_READY_NUMBER_INFO);
    }
  }

  handleReadyNumberSearchResult(result: AssetSearchResult): AssetSearchResult {
    if (this.doesSearchResultHaveAssets(result)) {
      return result;
    } else return this.NO_RESULT_FOR_READY_NUMBER_INFO;
  }

  private searchByReadyNumberOnly(searchTerm): Observable<AssetSearchResult> {
    return of(this.createSearchParamsForReadyNumberSearch(searchTerm)).pipe(
      switchMap((params: HttpParams) => this.getAssetsBySearchParams(params)),
      map((readyNumberRes: any) => {
        if (this.doesSearchResultHaveAssets(readyNumberRes)) {
          this.markAsFoundByReadyNumber(readyNumberRes);
          return readyNumberRes;
        } else return this.NO_RESULT_FOR_READY_NUMBER_INFO;
      })
    );
  }

  private markAsFoundByReadyNumber(readyNumberRes: AssetSearchResult) {
    readyNumberRes.foundByReadyNumber = true;
  }

  private getAssetsBySearchParams(searchParams: HttpParams): Observable<any> {
    return this.assetService.getAssetsBySearchParams(searchParams);
  }

  private createSearchParamsForReadyNumberSearch(term: string): HttpParams {
    let filteredParams = new HttpParams();
    filteredParams = filteredParams.append(this.READY_NUMBER_PARAM_NAME, term);
    return filteredParams;
  }

  private get authenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  private subscribeToUser() {
    if (this.authenticated) {
      this.authService.getCurrentUser().subscribe((user) => {
        this.user = user;
        this.authService.setUserGroups(this.user);
      });
    }
  }

  private doesSearchResultHaveAssets(searchResponse: any): boolean {
    return searchResponse.data.length > 0;
  }

  private termIsValidReadyNumberWithPartnerTagShortcut(term: string): boolean {
    if (!this.doesTermStartWithPartnerTagShortcut(term)) return false;
    const numberSegment: string = term.replace(this.PARTNER_TAG_SHORTCUT, ''); //removes only first occurence
    return this.numberSegmentOfReadyNumberValid(numberSegment);
  }

  private getTermWithShortcutReplacedByPartnerTag(term: string): string {
    if (!this.doesTermStartWithPartnerTagShortcut(term))
      throw new Error(
        'Error in getTermWithShortcutReplacedByPartnerTag(). String was expected to start with Shortcut Substring'
      );
    const numberSegment: string = term.replace(this.PARTNER_TAG_SHORTCUT, ''); //removes only first occurence
    return this.user.partnerTag + '-' + numberSegment;
  }

  private termIsValidReadyNumberWithPartnerTag(term: string): boolean {
    const indexOfMinus: number = term.indexOf('-');
    const termIncludesMinus = indexOfMinus !== -1;
    if (!termIncludesMinus) return false;

    const [partnerTagSegment, numberSegment] = term.split('-');
    const segmentBeforeMinusIsPartnerTag = partnerTagSegment == this.user.partnerTag;

    return segmentBeforeMinusIsPartnerTag && this.numberSegmentOfReadyNumberValid(numberSegment);
  }

  private numberSegmentOfReadyNumberValid(segment: string): boolean {
    const segmentHasReadyNumberLength = segment.length == this.READY_NUMBER_LENGTH;
    const digitOnlyRegex: RegExp = /^[0-9]+$/;
    const segmentConsistsOfDigitsOnly = digitOnlyRegex.test(segment);

    return segmentHasReadyNumberLength && segmentConsistsOfDigitsOnly;
  }
}
