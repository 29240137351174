import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AssetService } from './asset.service';
import { SchemasService } from './schemas.service';
import { ReadyNumberSearchHandlerService } from '../modules/asset-catalog/services/ready-number-search-handler.service';
import { AssetSearchResult } from '../modules/asset-catalog/models/asset-search-result';
import { EnumPublishedState } from '../business-domain/EnumPublishedState';
import { Router } from '@angular/router';

////////////////// MOVE TO PROXY //////////////////////
const NO_RESULT_FOUND: AssetSearchResult = {
  info: {
    icon: 'error',
    iconColor: '', // no color for gray icon
    title: 'Keine Ergebnisse',
    text: 'Bitte überprüfen Sie Ihre Eingabe und <br>versuchen Sie es erneut.',
  },
  pageCount: 0,
};

const SEARCH_TERM_TOO_SHORT: AssetSearchResult = {
  info: {
    icon: 'error',
    iconColor: '', // no color for gray icon
    title: 'Die Suche erfordert mindestens drei Zeichen',
    text: 'Bitte überprüfen Sie Ihre Eingabe und <br>versuchen Sie es erneut.',
  },
  pageCount: 0,
};

const SEARCH_RESULT_PENDING: AssetSearchResult = {
  info: { pending: true },
};
////////////////// MOVE TO PROXY //////////////////////

/*
For now simply called PropertyFilters to indicate that these fields concern object properties that every asset has
in contrast to the resultSubsetParameters which is not related to any kind of asset object property and also to differentiate from
those PropertyFilters specific to certain schemas.
*/
export interface PropertyFilters {
  schemaId?: string;
  searchTerm?: string;
  productStatus?: string;
  manufacturersId?: string;
  livingStyle?: string;
}

export interface ResultSubsetParameters {
  page?: number;
  limit?: number; // means the number of requested assets per page
}

type PropertyFilterKeys = keyof PropertyFilters;

const SEARCH_TERM_PARAM_NAME = 'q';
const SCHEMA_ID_FILTER_NAME = 'productSchema';
const PRODUCT_STATUS_FILTER_NAME = 'status';
const LIVING_STYLE_FILTER_NAME = 'livingStyle';
const MANUFACTURER_ID_FILTER_NAME = 'manufacturersId';
const PUBLISHED_FILTER_NAME = 'isPublished';

const MIN_SEARCH_TERM_LENGTH = 3;
const DEFAULT_NUMBER_OF_ASSERTS = 36;
const DEFAULT_PAGE = 1;

@Injectable({
  providedIn: 'root',
})
export class FilteredArticlesService {
  private readonly filteredArticlesSubject: BehaviorSubject<AssetSearchResult>;
  private readonly propertyFiltersSubject: BehaviorSubject<PropertyFilters>;
  private readonly resultSubsetParametersSubject: BehaviorSubject<ResultSubsetParameters>;
  private readonly searchParams: BehaviorSubject<HttpParams>;
  private readonly pendingRequestCancellation: Subject<void>;

  readonly propertyFilters$: Observable<PropertyFilters>;
  readonly resultSubsetParameters$: Observable<ResultSubsetParameters>;

  private publishedOnlyFilter: boolean; // TODO: Will be a filter

  private configurationId: string;
  private searchBlocked: boolean;

  constructor(
    public router: Router,
    private readyNumberSearchHandlerService: ReadyNumberSearchHandlerService,
    private assetService: AssetService,
    private schemasService: SchemasService
  ) {
    this.filteredArticlesSubject = new BehaviorSubject<AssetSearchResult>(null);
    this.propertyFiltersSubject = this.initializePropertyFiltersSubject();
    this.resultSubsetParametersSubject = this.initializeResultSubsetParametersSubject();
    this.searchParams = new BehaviorSubject<HttpParams>(null);

    this.propertyFilters$ = this.propertyFiltersSubject.asObservable();
    this.resultSubsetParameters$ = this.resultSubsetParametersSubject.asObservable();

    this.pendingRequestCancellation = new Subject<void>();
    this.searchBlocked = false;
    this.publishedOnlyFilter = true;

    // TODO FH-1054: connect setSearchParams by piping -> then no subscription should be necessary
    this.subscribeToSearchParams();
    this.initResetOfPageFilterOnRelevantChanges();
  }

  setSearchParams() {
    if (!this.searchBlocked) {
      let params = new HttpParams();

      const pageZeroBased = (+this.resultSubsetParameters.page - 1).toString(); // reset to 0-based counting for request
      params = params.append('page', pageZeroBased);
      params = params.append('limit', this.resultSubsetParameters.limit);

      if (this.configurationId) {
        params = params.append('configurationId', this.configurationId);
      }

      if (this.propertyFilters.schemaId) {
        params = params.append(SCHEMA_ID_FILTER_NAME, this.propertyFilters.schemaId);
      }

      // TODO: Since livingStyle is a number here, checking for "if(this.propertyFilters.livingStyle)" is not enough
      // TODO: Because 0 evaluates to null, so we need to check for null explicitly
      if (this.propertyFilters.livingStyle != null) {
        params = params.append(LIVING_STYLE_FILTER_NAME, this.propertyFilters.livingStyle);
      }

      if (this.propertyFilters.productStatus) {
        params = params.append(PRODUCT_STATUS_FILTER_NAME, this.propertyFilters.productStatus);
      }

      if (this.propertyFilters.searchTerm) {
        params = params.append(SEARCH_TERM_PARAM_NAME, this.propertyFilters.searchTerm);
      }

      if (this.propertyFilters.manufacturersId) {
        params = params.append(MANUFACTURER_ID_FILTER_NAME, this.propertyFilters.manufacturersId);
      }

      if (!this.publishedOnlyFilter) {
        params = params.append(PUBLISHED_FILTER_NAME, EnumPublishedState.REVIEWING);
      }

      this.searchParams.next(params);
    } else {
      this.filteredArticlesSubject.next({ data: [] });
    }
  }

  getFilteredArticles(): Observable<AssetSearchResult> {
    return this.filteredArticlesSubject.asObservable().pipe(filter((result: AssetSearchResult) => !!result));
  }

  getPropertyFilters(): Promise<PropertyFilters> {
    return this.propertyFiltersSubject.asObservable().pipe(take(1)).toPromise();
  }

  removeFavoriteStatusFromAllAssets() {
    if (this.filteredArticlesSubject.getValue().data.length) {
      let filteredArticlesUpdated = { data: [] };
      this.filteredArticlesSubject.getValue().data.forEach((asset) => {
        asset.isFavorite = false;

        /*
        Important: create a deep copy of asset in order to allow change detection to trigger.
        As a result, article cards detect a change of the value of @input article.
        */
        filteredArticlesUpdated.data.push({ ...asset });
      });
      this.filteredArticlesSubject.next(filteredArticlesUpdated);
    }
  }

  clearConfigurationId() {
    this.configurationId = '';
  }

  async updateConfigurationId(id) {
    this.configurationId = id || undefined;
  }

  setPublishedFilter(publishedOnly: boolean) {
    this.publishedOnlyFilter = publishedOnly;
  }

  isSearchTermLengthValid(term) {
    return term.length >= MIN_SEARCH_TERM_LENGTH;
  }

  isValidPageParameter(value: any): boolean {
    return this.isAPositiveInteger(value);
  }

  handleSearchTermsOfInsufficientLength() {
    this.cancelPendingRequests();
    this.broadcastSearchTermTooShort();
  }

  hasAsset(id: string): boolean {
    return this.filteredArticles.data?.some((asset) => asset.id === id);
  }

  hasOnlyOneArticle(): boolean {
    return this.filteredArticles.data?.length === 1;
  }

  isOnPageOne(): boolean {
    return this.resultSubsetParameters.page === 1;
  }

  setPageToPrecedingPage() {
    this.setPageFilter(this.resultSubsetParameters.page - 1);
  }

  private sendRequestBasedOnParams(searchParams: HttpParams): Observable<AssetSearchResult> {
    const searchTerm = searchParams.get(SEARCH_TERM_PARAM_NAME);
    if (!searchTerm || !this.doesSearchTermImplyReadyNumberSearch(searchTerm)) {
      return this.getAssetsBySearchParams(searchParams).pipe(
        map((result: AssetSearchResult) => {
          if (result.data.length) return result;
          else return NO_RESULT_FOUND;
        })
      );
    } else {
      return this.readyNumberSearchHandlerService.getResultForReadyNumberInput(searchTerm);
    }
  }

  private initializeResultSubsetParametersSubject() {
    return new BehaviorSubject<ResultSubsetParameters>({
      page: 1,
      limit: DEFAULT_NUMBER_OF_ASSERTS,
    });
  }

  private initializePropertyFiltersSubject() {
    /*
    TODO FH-1054: Maybe we should set all initial values to undefined instead null,
    so we can differentiate the states like "Alle Hersteller" from none being chosen?
    -> Such a check is needed here: asset-search-results -> shouldSchemaFilterBeSet
    */
    return new BehaviorSubject<PropertyFilters>({
      schemaId: undefined,
      searchTerm: null,
      productStatus: null,
      manufacturersId: null,
      livingStyle: localStorage.getItem('livingStyle'),
    });
  }

  private get propertyFilters(): PropertyFilters {
    return this.propertyFiltersSubject.getValue();
  }

  private get resultSubsetParameters(): ResultSubsetParameters {
    return this.resultSubsetParametersSubject.getValue();
  }

  private get filteredArticles(): AssetSearchResult {
    return this.filteredArticlesSubject.getValue();
  }

  // TODO FH-1054: Is subscription obsolete? Should the logic simply be called at the end of setSearchParams?
  private subscribeToSearchParams() {
    /*
    acts as state of the latest search -> allows to ignore search results of requests
    that have been made obsolete by a new request/requests via piping through switchMap
    and thereby changing the observable which is subscribed
    */
    this.searchParams
      .pipe(
        filter((params) => !!params), // Get all valid filter/params
        switchMap((searchParams) => {
          const requestRes = this.sendRequestBasedOnParams(searchParams);
          return merge(of(SEARCH_RESULT_PENDING).pipe(takeUntil(requestRes)), requestRes).
          pipe(takeUntil(this.pendingRequestCancellation));
        })
      )
      .subscribe((result: AssetSearchResult) => {
        this.filteredArticlesSubject.next(result);
      });
  }

  private getAssetsBySearchParams(searchParams: HttpParams): Observable<any> {
    return this.assetService.getAssetsBySearchParams(searchParams);
  }

  private doesSearchTermImplyReadyNumberSearch(searchTerm: string): boolean {
    return this.readyNumberSearchHandlerService.doesTermStartWithPartnerTagSegment(searchTerm);
  }

  ///////////////////// Logic for Dependencies - should this logic be part of this service or the overview-component / other service? //////////////////////
  private initResetOfPageFilterOnRelevantChanges() {
    this.propertyFilters$
      .pipe(
        map(() => this.resultSubsetParameters),
        filter((resultSubsetParameters) => {
          return resultSubsetParameters.page !== DEFAULT_PAGE;
        })
      )
      .subscribe(() => {
        this.setPageFilter(DEFAULT_PAGE);
      });
  }

  /////////////////////// BUSINESS LOGIC PROXY METHODS //////////////////////////
  private cancelPendingRequests() {
    this.pendingRequestCancellation.next();
  }

  private broadcastSearchTermTooShort() {
    this.filteredArticlesSubject.next(SEARCH_TERM_TOO_SHORT);
  }

  /*
   FH-1054: Place this into global utility service?
  */
  private isAPositiveInteger(value: number): boolean {
    const isValid = typeof value === 'number' && Number.isInteger(value) && value > 0;
    return isValid;
  }

  setPageFilterAndSearchParams(page: number) {
    this.setPageFilter(page);
    this.setSearchParams();
  }

  // TODO ============ Move to Filter-Class =================
  setFilter(filterName: PropertyFilterKeys, filterValue: string) {
    const currentFilters = this.propertyFilters;
    currentFilters[filterName] = filterValue;
    this.propertyFiltersSubject.next(currentFilters);
  }

  // TODO FH-1054: Outsource checking schemaName
  async setSchemaFilter(schemaName: string) {
    let schemaId: any;
    if (schemaName) {
      schemaId = await this.schemasService.getSchemaIdByName(schemaName);
    } else {
      schemaId = null;
    }
    this.setFilter('schemaId', schemaId);
  }

  // TODO FH-1054: Outsource business logic
  setSearchTermFilter(term: string) {
    term = term.trim();
    if (
      term.length < MIN_SEARCH_TERM_LENGTH &&
      term.length !== 0 &&
      !this.readyNumberSearchHandlerService.doesTermStartWithPartnerTagSegment(term)
    ) {
      this.searchBlocked = true;
    } else {
      this.searchBlocked = false;

      this.setFilter('searchTerm', term);
    }
  }

  // Type is LivingStyle-Enum
  setLivingStyleFilter(value: any) {
    this.setFilter('livingStyle', value);
    value != null
      ? localStorage.setItem('livingStyle', value)
      : localStorage.removeItem('livingStyle');
  }

  setProductStatusFilter(value: any) {
    this.setFilter('productStatus', value);
  }

  setManufacturerFilter(value: any) {
    this.setFilter('manufacturersId', value);
  }

  setPageFilter(page: number) {
    const currentFilters = this.resultSubsetParameters;
    currentFilters['page'] = page;
    this.resultSubsetParametersSubject.next(currentFilters);
  }

  ////////////////// CLEARING PropertyFilters //////////////////
  clearAllPropertyFilters() {
    /*
    TODO: Consider replacing this with just the following:
    this.propertyFiltersSubject.next({});
    */
    this.propertyFiltersSubject.next({
      schemaId: null,
      searchTerm: null,
      productStatus: null,
      manufacturersId: null,
      livingStyle: null,
    });
  }

  clearAllPropertyFiltersExcludingSearchTerm() {
    const filters = this.propertyFilters;
    const searchTerm = filters.searchTerm;

    /*
    TODO: Consider replacing this with just the following:
    this.propertyFiltersSubject.next({ searchTerm: searchTerm });
    */
    this.propertyFiltersSubject.next({
      schemaId: null,
      productStatus: null,
      manufacturersId: null,
      livingStyle: null,
      searchTerm: searchTerm,
    });
  }
  // TODO ============ Move to Filter-Class =================
}
