import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorDialogService } from './errordialog.service';
import { environment } from '../../environments/environment';

export enum PropertyType {
  ENUM = 'ENUM',
  FILE = 'FILE',
  MULTISELECT = 'MULTISELECT',
  NUMBER = 'NUMBER',
  PRODUCT_IMAGES = 'PRODUCTIMAGES',
  STRING = 'STRING',
  TEXTAREA = 'TEXTAREA',
}

export enum PropertyVisibilityLevel {
  CATALOGUE = 'CATALOGUE',
  DETAIL = 'DETAIL',
  FINGERHAUS = 'FINGERHAUS',
  LOG = 'LOG',
  MANAGEMENT = 'MANAGEMENT',
  NONE = 'NONE',
}

export interface SchemaProperty {
  defaultValue: string;
  displayName: string;
  isInheritable: number;
  name: string;
  order: number;
  propertyId: string;
  required: number;
  schemaId: string;
  type: PropertyType;
  visibilityLevel: PropertyVisibilityLevel;
}

@Injectable({
  providedIn: 'root',
})
export class SchemaPropertiesService {
  // TODO: set desired language globally
  private locale = 'de';

  constructor(private http: HttpClient, private errorDialogService: ErrorDialogService) {}

  // TODO: Add logic in order to get properties from schemaTree in case one was already loaded before
  async getPropertiesOfSchema(
    schemaId: string,
    includeInherited: boolean,
    includeEnums: boolean
  ): Promise<SchemaProperty[]> {
    let params = new HttpParams();
    let options: any;
    let res: any;
    let properties: SchemaProperty[];

    if (includeEnums) params = params.append('includeEnums', 'true');
    if (includeInherited) params = params.append('includeInherited', 'true');

    options = { params: params };
    res = await this.http
      .get(environment.apiUrl + '/schemas/' + schemaId + '/properties', options)
      .toPromise();

    properties = res.properties;

    this.parseDisplayNameWithLocale(properties); // TODO: consider not manipulating the object, but assign a returned object
    return properties;
  }

  filterPropertiesByVisibilityLevel(
    properties: SchemaProperty[],
    visibilityLevels: PropertyVisibilityLevel[]
  ): SchemaProperty[] {
    return properties.filter((property) => visibilityLevels.includes(property.visibilityLevel));
  }

  filterPropertiesByType(properties: SchemaProperty[], type: PropertyType[]): SchemaProperty[] {
    return properties.filter((property) => type.includes(property.type));
  }

  sortPropertiesByOrderValue(properties: SchemaProperty[]) {
    return properties.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  /**
   * Checks if the given property value is considered empty as per our policy (defined by POR-10125).
   * Empty means it does not have a value which can be considered a meaningful value of the property.
   *
   * @param value - The value to check.
   * @returns True if the value is null or undefined or an empty string, false otherwise.
   */
  isPropertyValueEmpty(value): boolean {
    return value === null || value === undefined || value === '';
  }

  private parseDisplayNameWithLocale(properties: any): void {
    // TODO QA: Want to use SchemaProperty[] here but "property.enums" is troublesome. is this a valid field on the SchemaProperty?
    for (let i = 0; i < properties.length; i++) {
      let property = properties[i];

      //parse displayName of schema
      try {
        if (JSON.parse(property.displayName))
          property.displayName = JSON.parse(property.displayName)[this.locale];
      } catch (error) {
        //console.error(error, 'Error in trying to parse displayName of property ', property);
        this.errorDialogService.openDialog({
          errorCode: '0xFFFFFF',
          location: 'schemas-service, getPropertiesOfSchema()',
          devinfo: error.message /* Standard (falls verfügbar): error.message */,
          publicinfo:
            'Beim Auslesen der Produktkategorie ist ein Fehler aufgetreten. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
          debug: false /* Standardwert, nur für Entwickler zur Fehlersuche true */,
          autoFocus: false /* Standardwert */,
        });
      }

      // parse displayName of enums
      if (property.enums) {
        for (let j = 0; j < property.enums.length; j++) {
          let enu = property.enums[j];

          try {
            if (JSON.parse(enu.displayName))
              enu.displayName = JSON.parse(enu.displayName)[this.locale];
          } catch (error) {
            //console.error(error, 'Error in trying to parse displayName of property ', property);
            this.errorDialogService.openDialog({
              errorCode: '0xFFFFFF',
              location: 'schemas-service, getPropertiesOfSchema()',
              devinfo: error.message /* Standard (falls verfügbar): error.message */,
              publicinfo:
                'Beim Auslesen der Produktkategorie ist ein Fehler aufgetreten. <br>Mögliche Lösungen finden Sie in unserem <a href="https://support.porter.de" target="_blank">Help- & Supportcenter</a>. Bestehen dann noch Fragen, wenden Sie sich bitte direkt an unseren <a href="mailto:support@porter.de">Support</a>.',
              debug: false /* Standardwert, nur für Entwickler zur Fehlersuche true */,
              autoFocus: false /* Standardwert */,
            });
          }
        }
      }
    }
  }
}
