import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { APP_PATHS } from './route-paths';

import { LoginComponent } from './components/signup/login/login.component';
import { RegisterComponent } from './components/signup/register/register.component';
import { ResetPasswordComponent } from './components/signup/login/reset-password/reset-password.component';
import { RequestPasswordResetComponent } from './components/signup/login/request-password-reset/request-password-reset.component';
import { ConfirmationComponent } from './components/signup/register/confirmation/confirmation.component';
import { ManufacturerComponent } from './components/manufacturer/manufacturer.component';
import { DownloadComponent } from './components/download/download.component';
import { VisualUnitsComponent } from './components/visual-units/visual-units.component';

import { AuthGuard } from './auth.guard';
import { UserGroupGuard } from './user-group.guard';
import { OnboardingScreenGuard } from './onboarding-screen.guard';
import { ConfigurationResolverService } from './resolvers/configuration-resolver.service';
import { PreloadingStrategyService } from './services/preloading-strategy.service';

const routes: Routes = [
  {
    path: APP_PATHS.dashboard,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      breadcrumb: 'PORTER Dashboard',
      userGroup: [environment.adminGroup],
      // TODO: reactivate when dashboard is needed again. Currently deactivated for all users but admins
      // .concat(environment.PORTER_MANUFACTURER_GROUPS)
      // .concat(environment.STREAMING_LICENCE_GROUP)
      // .concat(environment.AI_LICENCE_GROUPS)
      // .concat(environment.PORTER_LICENCE_GROUPS),
    },
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },

  {
    path: APP_PATHS.assetCatalog,
    canActivate: [AuthGuard, UserGroupGuard, OnboardingScreenGuard],
    resolve: { configurationId: ConfigurationResolverService },
    data: {
      preload: false,
      userGroup: environment.FINGERHAUS_ADMIN_GROUP.concat(environment.FINGERHAUS_BUILDER)
        // currently added non-ai groups to this route instead of dashboard
        .concat(environment.PORTER_MANUFACTURER_GROUPS)
        .concat(environment.STREAMING_LICENCE_GROUP)
        .concat(environment.PORTER_LICENCE_GROUPS),
      onboardingScreenGroup: environment.FINGERHAUS_BUILDER,
    },
    loadChildren: () =>
      import('./modules/asset-catalog-web-wrapper/asset-catalog-web-wrapper.module').then(
        (m) => m.AssetCatalogWebWrapperModule
      ),
  },

  {
    path: APP_PATHS.onboardingScreen,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      userGroup: environment.FINGERHAUS_ADMIN_GROUP.concat(environment.FINGERHAUS_BUILDER)
        .concat(environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP)
        .concat(environment.FINGERHAUS_CONSULTANT_GROUP),
    },
    loadChildren: () =>
      import('./modules/onboarding-screen/onboarding-screen.module').then(
        (m) => m.OnboardingScreenModule
      ),
  },

  {
    path: APP_PATHS.assetCatalogOnly,
    canActivate: [AuthGuard, UserGroupGuard],
    resolve: { configurationId: ConfigurationResolverService },
    data: {
      preload: false,
      userGroup: environment.PORTER_LICENCE_GROUPS,
    },
    loadChildren: () =>
      import('./modules/asset-catalog-only-wrapper/asset-catalog-only-wrapper.module').then(
        (m) => m.AssetCatalogOnlyWrapperModule
      ),
  },

  {
    path: APP_PATHS.assetManagement,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      userGroup: environment.FINGERHAUS_ADMIN_GROUP.concat(
        environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP
      ).concat(environment.FINGERHAUS_CONSULTANT_GROUP),
    },
    loadChildren: () =>
      import('./modules/article-management/article-management.module').then(
        (m) => m.ArticleManagementModule
      ),
  },
  {
    path: APP_PATHS.buildingProjects,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      userGroup: environment.FINGERHAUS_CONSULTANT_GROUP,
    },
    loadChildren: () =>
      import('./modules/building-projects/building-projects.module').then(
        (m) => m.BuildingProjectsModule
      ),
  },

  {
    path: APP_PATHS.service,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      userGroup: environment.PORTER_LICENCE_GROUPS,
    },
    loadChildren: () => import('./modules/service/service.module').then((m) => m.ServiceModule),
  },

  {
    path: APP_PATHS.floorPlans,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      breadcrumb: 'PORTER Grundrisse Dashboard',
      userGroup: environment.AI_LICENCE_GROUPS,
    },
    loadChildren: () =>
      import('./modules/porter-grundrisse/porter-grundrisse.module').then(
        (m) => m.PorterGrundrisseModule
      ),
  },

  {
    path: APP_PATHS.projects,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      breadcrumb: 'PORTER Bemusterung Projektübersicht',
      userGroup: environment.PORTER_LICENCE_GROUPS,
    },
    loadChildren: () =>
      import('./modules/porter-bemusterung/porter-bemusterung.module').then(
        (m) => m.PorterBemusterungModule
      ),
  },

  {
    path: APP_PATHS.login,
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'PORTER Login',
    },
  },

  {
    path: APP_PATHS.manufacturers,
    component: ManufacturerComponent,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      breadcrumb: 'PORTER Hersteller',
    },
  },

  {
    path: APP_PATHS.register,
    component: RegisterComponent,
    data: {
      breadcrumb: 'PORTER Registrierung',
    },
  },

  {
    path: APP_PATHS.reset,
    data: {
      breadcrumb: 'PORTER Passwort zurücksetzen',
    },
    children: [
      {
        path: ':token',
        component: ResetPasswordComponent,
      },
      {
        path: '',
        component: RequestPasswordResetComponent,
      },
    ],
  },

  {
    path: APP_PATHS.confirmation,
    component: ConfirmationComponent,
    data: {
      breadcrumb: 'PORTER Account aktivieren',
    },
  },

  {
    path: APP_PATHS.appLink,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      breadcrumb: 'PORTER Desktop Solution starten',
      userGroup: environment.PORTER_LICENCE_GROUPS.concat(environment.PORTER_MANUFACTURER_GROUPS)
        .concat(environment.STREAMING_LICENCE_GROUP)
        .concat(environment.AI_LICENCE_GROUPS)
        .concat([environment.adminGroup]),
    },
    loadChildren: () => import('./modules/app-link/app-link.module').then((m) => m.AppLinkModule),
  },

  {
    path: APP_PATHS.download,
    component: DownloadComponent,
    data: {
      breadcrumb: 'PORTER Download',
    },
  },

  {
    path: APP_PATHS.visualUnits,
    component: VisualUnitsComponent,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      userGroup: [environment.adminGroup].concat(environment.FINGERHAUS_ADMIN_GROUP),
      breadcrumb: 'PORTER Visual Units',
    },
  },

  {
    path: APP_PATHS.webProtocol,
    canActivate: [AuthGuard, UserGroupGuard],
    data: {
      preload: false,
      breadcrumb: 'PORTER Bemusterungs-Protokoll',
      userGroup: environment.PORTER_LICENCE_GROUPS,
    },
    loadChildren: () =>
      import('./modules/web-protocol/web-protocol.module').then((m) => m.WebProtocolModule),
  },

  // redirect empty url to produkt-katalog
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/grundrisse',
  },

  // catch all route (= "wildcard route"), if the route doesn't match any of the others
  {
    // TODO: reactivate instead of redirect once dashboard is functional
    path: '**',
    // canActivate: [AuthGuard, UserGroupGuard],
    redirectTo: '/grundrisse',
    // data: {
    //   userGroup: environment.PORTER_LICENCE_GROUPS.concat(environment.PORTER_MANUFACTURER_GROUPS)
    //     .concat(environment.STREAMING_LICENCE_GROUP)
    //     .concat(environment.AI_LICENCE_GROUPS)
    //     .concat([environment.adminGroup]),
    // },
    // loadChildren: () =>
    //   import('./modules/asset-catalog-web-wrapper/asset-catalog-web-wrapper.module').then(
    //     (m) => m.AssetCatalogWebWrapperModule
    //   ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadingStrategyService,
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
