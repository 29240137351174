import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Data,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { map } from 'rxjs/operators';
import { User } from './business-domain/User';
import { environment } from '../environments/environment';

@Injectable()
export class UserGroupGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getCurrentUser().pipe(
      map((user) => {
        // initialize user groups in authService
        // TODO: should be refactored to be in the app-initialize service (or similar)
        // to avoid that the authService is not initialized when the UserGroupGuard
        // is not used in the route
        this.authService.setUserGroups(user);

        const data = next.data;
        if (
          (this.doesRouteDefineARequiredUserGroup(data) &&
            this.userIsInUserGroup(user, data.userGroup)) ||
          user.admin
        ) {
          return true;
        }

        if (next.routeConfig.path === 'projects') {
          this.router.navigate(['/grundrisse'], {
            queryParams: { returnUrl: window.location.pathname + window.location.search },
          });
        } else if (this.authService.userIsInUserGroup(user, environment.FINGERHAUS_BUILDER)) {
          this.router.navigate(['/produkt-katalog']);
        } else if (
          this.authService.userIsInUserGroup(
            user,
            environment.FINGERHAUS_PRODUCT_MANAGEMENT_GROUP
          ) ||
          this.authService.userIsInUserGroup(user, environment.FINGERHAUS_CONSULTANT_GROUP) ||
          this.authService.userIsInUserGroup(user, environment.FINGERHAUS_ADMIN_GROUP)
        ) {
          this.router.navigate(['/produkt-management']);
        } else {
          if (this.authService.userIsInUserGroup(user, [environment.adminGroup]))
            this.router.navigate(['/dashboard'], {
              queryParams: { returnUrl: window.location.pathname + window.location.search },
            });
          else this.router.navigate(['/app-link']);
        }
        return false;
      })
    );
  }

  private doesRouteDefineARequiredUserGroup(data: Data): boolean {
    return !!data?.userGroup;
  }

  private userIsInUserGroup = (user: User, userGroups: string[]) => {
    try {
      return userGroups.filter((v) => user.groups.includes(v)).length > 0;
    } catch (error) {
      throw error; // TODO: Throw porter error
    }
  };
}
