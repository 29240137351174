import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SharedRoutingModule } from './shared-routing.module';

import { DragDropDirective } from './directives/dragndrop.directive';
import { ShowTooltipIfTruncatedDirective } from './directives/show-tooltip-if-truncated.directive';
import { AlertDirective } from '../directives/alert.directive';
import { MatSelectModule } from '@angular/material/select';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProjectFormComponent } from './components/project-form/project-form.component';
import { TopMenuComponent } from './components/menus/top-menu.component';
import { ModalComponent } from '../components/modal/modal.component';
import { SignupFooterComponent } from './components/signup-footer/signup-footer.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { QuickMenuComponent } from './components/quickmenu/quick-menu.component';
import { SupportButtonComponent } from '../components/support-button/support-button.component';
import {ProductNoteComponent} from "./components/product-note/product-note.component";

/* Reconsider future usage of user permissions as planned in FH-204; See same comment in other components
import { UserPermissionDirective } from './directives/user-permission.directive'; */

@NgModule({
  declarations: [
    //UserPermissionDirective,
    AlertDirective,
    ShowTooltipIfTruncatedDirective,
    TopMenuComponent,
    SidebarComponent,
    ProjectFormComponent,
    DragDropDirective,
    ModalComponent,
    NavigationBarComponent,
    SignupFooterComponent,
    DropdownComponent,
    QuickMenuComponent,
    SupportButtonComponent,
    ProductNoteComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SharedRoutingModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  exports: [
    TopMenuComponent,
    SidebarComponent,
    ProjectFormComponent,
    DragDropDirective,
    ShowTooltipIfTruncatedDirective,
    AlertDirective,
    ModalComponent,
    NavigationBarComponent,
    SignupFooterComponent,
    DropdownComponent,
    QuickMenuComponent,
    SupportButtonComponent,
    ProductNoteComponent,
  ],
})
export class SharedModule {}